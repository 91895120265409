import React from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../network/ApiUrl";

function DriverCard(props) {
  const { item, selectedServiceid } = props;
  console.log(item);
  return (
    <>
      <div className="row">
        <div className="col-md-4 col-sm-4 col-6">
          <Link
            to={{
              pathname: "/providerprofile",
              state: {
                service: selectedServiceid,
                provider: item?.id,
              },
            }}
            className="driverCard-mainContainer"
          >
            <div className="image-container">
              <img
                src={`${ImageUrl}${item?.avatar}`}
                className="image-fluid image-width"
              />
            </div>
            <div style={{ marginLeft: "12px", marginTop: "16px" }}>
              <p className="user-name">{item?.full_name}</p>
            </div>
          </Link>
        </div>
        <div className="col-md-4 col-sm-4 col-6">
          <div
            style={{ display: "flex", alignItems: "center", height: "105px" }}
          >
            <div className="flexBox-style">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="certified-badge"
              />
              <p className="driver-detail">Certified Driver</p>
            </div>
            <div className="flexBox-style BookingRating-container">
              <p className="driveBooking-rating">4.0</p>
              <span className="fa fa-star checked star-icon"></span>
            </div>
          </div>
        </div>
        {/* <div className="col-md-4 col-sm-4 col-12">
                    <div style={{textAlign:'center',  marginTop: '20px'}}>
                        <button type="button" className="btn btn-info chat-Btn">Chat</button>
                    </div>  
                </div> */}
      </div>
    </>
  );
}

export default DriverCard;
