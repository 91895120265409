import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { profile2, doodle } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import { ImageUrl } from "../../network/ApiUrl";
import {
  AddPaymentMethods,
  DeletePaymentMethodsApi,
  GetAllPaymentMethods,
} from "../../network/Network";
import { AllPaymentMethod } from "../../redux/actions/AuthAction";
import AddPaymentMethod from "../../components/PayementMethod/AddPaymentMethod";
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import { SpinnerCircular } from "spinners-react";

function Profile(props) {
  let history = useHistory();
  let dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const UserData = useSelector((state) => state.AuthReducer.users);

  const [paymentData, setPaymentData] = useState([]);
  const [token_visa, setToken_visa] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);

  console.log({ paymentData });

  // == stripe Token ==
  const onToken = (token) => {
    console.log("tok", token);
    setToken_visa(token?.id);
    let data = {
      token: token?.id,
    };
    if (data) {
      AddPaymentMethods(data, Token)
        .then((res) => {
          setPaymentData(res?.data?.data);
          console.log(res?.data?.data);
          history.push("/payment");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    GetAllPaymentMethods(Token)
      .then((res) => {
        console.log(res);
        dispatch(AllPaymentMethod(res?.data?.data));
        //   paymentData?.slice(res?.data?.data?.length, 1)
        setPaymentData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [paymentData]);

  const CardDta = [
    // {
    //       id: 1,
    //       cardTitle: 'Card 01',
    //       cardNumber: '**** **** **** 4543',
    //       checkLabel: 'Default'
    // },
    {
      id: 1,
      cardTitle: "Card 02",
      cardNumber: "**** **** **** 4543",
      checkLabel: "Make this Default",
    },
    {
      id: 2,
      cardTitle: "Card 03",
      cardNumber: "**** **** **** 4543",
      checkLabel: "Make this Default",
    },
  ];

  const DeletePaymentMethod = (id, index) => {
    setPaymentLoading(true);
    console.log(id);
    DeletePaymentMethodsApi(id, Token)
      .then((res) => {
        console.log(res);
        paymentData?.splice(index, 1);
        setPaymentData(res?.data?.data);
        setPaymentLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPaymentLoading(false);
      });
  };

  console.log(paymentData);
  return (
    <>
      <div className="Home-main-Container service profile">
        <div className="container">
          <h2 className="dashboard-content-Title">My Profile</h2>
          <p
            className="dashboard-content-SubTitle"
            style={{ marginBottom: "76px" }}
          >
            Profile
          </p>

          <div className="contact-container">
            <div className="profile-card-container">
              <div className="flexBox-style">
                <div className="profile-pic">
                  <img
                    src={`${ImageUrl}${UserData?.avatar}`}
                    className="image-fluid image-width"
                  />
                </div>
                <div className="doodle-container">
                  <img src={doodle} className="image-fluid" alt="" />
                </div>
              </div>
              <h3 className="user-name">{UserData?.full_name}</h3>

              <div className="row">
                <div className="col-md-6">
                  <div className="flexBox-style-space">
                    <p className="sub-Title">Basic Information</p>
                    <Link to="/editprofile" className="edit-profile">
                      Edit Profile
                    </Link>
                  </div>
                  <div className="user-Detail-container">
                    <div className="userDetail-subContainer">
                      <div>
                        <p className="user-Title">Firstname</p>
                        <p className="user-data">{UserData?.first_name}</p>
                      </div>
                      <div>
                        <p className="user-Title">Lastname</p>
                        <p className="user-data">{UserData?.last_name}</p>
                      </div>
                      <div>
                        <p className="user-Title">Phone</p>
                        <p className="user-data">{UserData?.phone}</p>
                      </div>
                      <div>
                        <p className="user-Title">Email</p>
                        <p className="user-data">{UserData?.email}</p>
                      </div>
                      <div>
                        <p className="user-Title">Address</p>
                        <p className="user-data">{UserData?.address}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="sub-Title">Payment</p>
                  <div className="user-Detail-container">
                    <div className="userDetail-subContainer userContainer_height">
                      {paymentData.length >= 0 ? (
                        paymentData?.map((item, index) => {
                          return (
                            <AddPaymentMethod
                              item={item}
                              index={index}
                              DeletePaymentMethod={DeletePaymentMethod}
                              paymentLoading={paymentLoading}
                            />
                          );
                        })
                      ) : (
                        <div className="loader-container">
                          <SpinnerCircular size="80px" color="#5C3DA4" />
                        </div>
                      )}
                    </div>
                    <div className="addNewCard-btn">
                      <StripeCheckout
                        // amount = '10.00'
                        name="STRIPE_INTEGRATION"
                        label="Add New Payment"
                        // description={`Order of ${computeQuantity(cart)} items!`}
                        // image='LINKTOIMAGE'
                        stripeKey="pk_test_51JrnF3KzMNWu8IhY59SUNnExbLog1Oz5CMpnkmWk8GaROLwXsW2OWyZQB4EH61fe5BUvQNjG3AnsNaUgJtE2ps6V00IUI06OvF"
                        // currency="INR"
                        token={onToken}
                      >
                        <button
                          className="btn btn-primary addNew-Btn"
                          type="submit"
                        >
                          Add New
                        </button>
                      </StripeCheckout>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
