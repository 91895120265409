import {
  API,
  AUTH,
  FROGOT_PASSWORD,
  SERVICES,
  UPDATE_PROFILE,
  LOGIN,
  RESEST,
  SIGNUP,
  JOBS,
  NOTIFICATION,
  PROVIDERS,
  RATINGS,
  PAYMENT_METHODS,
  USER_SERVICES,
  CHATS,
  NOTIFICATIONS,
  CONTACTUS,
  GETREVIEW,
} from "../network/Endpoint";

import {
  doGet,
  doPost,
  doPatch,
  doDelete,
  doPostProfilePictureUpload,
} from "./Config";

export const postSignIn = (data) => {
  return doPost(`${API}${AUTH}${LOGIN}`, { ...data });
};

export const postSignUp = (data) => {
  return doPost(`${API}${AUTH}${SIGNUP}`, data);
};

export const forgotPasswordEmailApi = (data) => {
  return doPost(`${API}${AUTH}${RESEST}`, data);
};

export const forgotPasswordApi = (data) => {
  return doPost(`${API}${AUTH}${FROGOT_PASSWORD}`, data);
};

export const setNewPasswordApi = (data) => {
  // return doPost(`${AUTH}${}`, data);
};
export const logoutApi = (data) => {
  // return doPost(`${AUTH}${}`, data);
};

export const GetAllServices = (token) => {
  return doGet(`${API}${SERVICES}`, token);
};

export const GetAllUserServices = (token) => {
  return doGet(`${API}${USER_SERVICES}`, token);
};

export const PostAddUserServices = (data, token) => {
  return doPost(`${API}${USER_SERVICES}`, data, token);
};

export const DeleteUserServicesApi = (id, token) => {
  return doDelete(`${API}${USER_SERVICES}/${id}`, token);
};

export const GetAllJOBS = (token) => {
  return doGet(`${API}${JOBS}`, token);
};

export const POSTJOBS = (data, token) => {
  return doPost(`${API}${JOBS}`, data, token);
};

export const UpdateJOB = (id, data, token) => {
  return doPatch(`${API}${JOBS}/${id}`, data, token);
};

export const GetAllNotifications = (token) => {
  return doGet(`${API}${NOTIFICATION}`, token);
};

export const GetAllProviders = (token, id) => {
  return doGet(`${API}${PROVIDERS}/${id}`, token);
};

export const UpdateProfile = (data, token) => {
  return doPostProfilePictureUpload(
    `${API}${AUTH}${UPDATE_PROFILE}`,
    data,
    token
  );
};

export const BookingFinishedRating = (data, token) => {
  return doPost(`${API}${RATINGS}`, data, token);
};

export const GetAllPaymentMethods = (token) => {
  return doGet(`${API}${PAYMENT_METHODS}`, token);
};

export const AddPaymentMethods = (data, token) => {
  return doPost(`${API}${PAYMENT_METHODS}`, data, token);
};

export const DeletePaymentMethodsApi = (id, token) => {
  return doDelete(`${API}${PAYMENT_METHODS}/${id}`, token);
};

export const GetChats = (id, token) => {
  return doGet(`${API}${CHATS}/${id}`, token);
};

// export const PostChats = (data,token,) => {
//   return doPost(`${API}${CHATS}`, data,token);
// };

export const PostChats = (data, token) => {
  return doPostProfilePictureUpload(`${API}${CHATS}`, data, token);
};

export const GetAllChats = (token) => {
  return doGet(`${API}${CHATS}`, token);
};

export const PostContactus = (data, token) => {
  return doPost(`${API}${CONTACTUS}`, data, token);
};

export const GetAllReview = (token) => {
  return doGet(`${API}${GETREVIEW}`, token);
};
