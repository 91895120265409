import React, { useState, useEffect } from "react";
import DriverCard from "../../components/DriverCard/DriverCard";
import { faSearch, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useLocation } from "react-router-dom";
import { user1, user2, user3 } from "../../constant";
import { GetAllProviders } from "../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { StoreProviderServices } from "../../redux/actions/AuthAction";

function DriveBooking(props) {
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedServiceid = location.state;

  const token = useSelector((state) => state.AuthReducer.token);
  const data = [
    {
      id: 1,
      name: "Martha K",
      image: user1,
    },
    {
      id: 2,
      name: "Albert Froster",
      image: user2,
    },
    {
      id: 3,
      name: "Naveen Daniel",
      image: user3,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [providerServiceData, setProviderServiceData] = useState([]);

  const gellAllProviderApi = () => {
    setLoading(true);
    GetAllProviders(token, selectedServiceid)
      .then((res) => {
        console.log(res);
        setProviderServiceData(res?.data?.data);
        dispatch(StoreProviderServices(res?.data?.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setLoading(false);
  };

  useEffect(() => {
    gellAllProviderApi();
  }, [loading]);

  console.log(providerServiceData);
  return (
    <>
      <div className="Home-main-Container service driveBooking">
        <div className="container">
          <h2 className="dashboard-content-Title">Book a Driver</h2>
          <p
            className="dashboard-content-SubTitle"
            style={{ marginBottom: "62px" }}
          >
            Home - Services
          </p>
          <div
            className="back-container"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </div>
          <div className="search-container">
            <FontAwesomeIcon
              icon={faSearch}
              className="search-icon"
              onClick={() => console.log("click")}
            />
            <input type="text" placeholder="Search Providers" />
          </div>

          <div className="contact-container">
            <h3 className="title">Suggested Drivers</h3>
            <div className="profile-card-container">
              {providerServiceData.length < 1 ? (
                <>
                  <div className="loader-container">
                    <SpinnerCircular size="80px" color="#5C3DA4" />
                  </div>
                </>
              ) : (
                providerServiceData?.map((item, index) => {
                  return (
                    <>
                      <DriverCard
                        item={item}
                        selectedServiceid={selectedServiceid}
                      />
                    </>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DriveBooking;
