import React,{useState,useEffect} from 'react';
import AllServicesDetail from '../components/AllServicesDetail/AllServicesDetail';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { airplane,cargoship,deliveryman,deliverytruck,locationpin,speechbubble } from "../constant";
import { DeleteUserServicesApi, GetAllServices,GetAllUserServices, PostAddUserServices } from '../network/Network';
import { useSelector, useDispatch } from 'react-redux';
import { AllServices } from '../redux/actions/AuthAction';

function Service(props) {
    let history = useHistory();
    let dispatch = useDispatch();
    const token = useSelector(state => state.AuthReducer.token);
    const Role = useSelector(state => state.AuthReducer.users.role);
    const Services = useSelector(state => state.AuthReducer.services);
    const [addService, setAddService] = useState(true)
    const [serviceId, setServiceId] = useState("")
    
    const [userServiceData,setUserServiceData] = useState([])

    // const [allServiceData,setAllServiceData] = useState([])
    
  const data=[
      {
          id:1,
          image: locationpin,
          title: "Driver",
          description: 'Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing  '
      },
      {
        id:2,
        image: deliveryman,
        title: "Delivery",
        description: 'Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing  '
    },
    {
        id:3,
        image: deliverytruck,
        title: "Shipping",
        description: 'Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing  '
    },
    {
        id:4,
        image: airplane,
        title: "Shipping",
        description: 'Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing  '
    },
    {
        id:5,
        image: cargoship,
        title: "Shipping",
        description: 'Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing  '
    },
  ]

    useEffect(() => {
        if(Role === "Customer") {
            GetAllServices(token)
            .then((res) => {
                console.log("CUSTOMER",res)
                // setAllServiceData(res?.data?.data)
                dispatch(AllServices(res?.data?.data))
            })
            .catch((err) => {
                console.log(err)
            })
        }
        else {
            GetAllUserServices(token)
            .then((res) => {
                console.log("PROVIDER",res)
                setUserServiceData(res?.data?.data)
                dispatch(AllServices(res?.data?.data))
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }, [])

    const AddUserServices = (id) => {
        console.log(id)
        setAddService(!addService)
        PostAddUserServices(id,token)
        .then((res) => {
            console.log(res)
            })
        .catch((err) => {
            console.log(err)
        })
    } 

    const DeleteUserServices = (id,index) => {
        console.log(id)
        setAddService(!addService)
        DeleteUserServicesApi(id,token)
        .then((res) => {
            console.log(res)
            setUserServiceData(res?.data?.data)
            dispatch(AllServices(res?.data?.data))
            userServiceData.splice(index, 1);
            })
        .catch((err) => {
                console.log(err)
        })
    } 

    return (
        <>
            <div className="Home-main-Container service">
                <div className="container">
                    <h2 className="dashboard-content-Title">All Services</h2>
                    <p className="dashboard-content-SubTitle" style={{ marginBottom: '76px'}}>Services</p>
                    <div 
                        className="back-container"
                        onClick={()=>{history.goBack()}}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="back-icon"/>
                    </div>
                    <div className="contact-container">
                        <div className="service-card-container">
                            {
                                Services?.map((item,index)=>{
                                    return(
                                        <AllServicesDetail 
                                            item={item} index={index}
                                            AddUserServices={AddUserServices}
                                            addService={addService}
                                            setAddService={setAddService}

                                            serviceId={serviceId}
                                            setServiceId={setServiceId}

                                            DeleteUserServices={DeleteUserServices}
                                        />
                                    )
                                })
                            }
        
                        </div>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default Service;
