import React from 'react'
import ProviderProgressBar from '../ProviderProfile/ProviderProgressBar';
import { useHistory } from "react-router-dom";
import {skills2,yourlocation,onlinePayment} from "../../constant";
import { useSelector, useDispatch } from 'react-redux';

function ProviderJobBanner(props) {
    const {type} = props;
    const history = useHistory();
    const UserData = useSelector(state => state.AuthReducer.users);
    console.log({UserData})
    const data= [
        {
            id: 1,
            num: `${UserData?.completed_jobs}`,
            title: "Jobs Done",
            image: skills2,
            status: 'Progress',
        },
        {
            id: 2,
            num: '$500 ',
            title: "Total Payments",
            image: onlinePayment,
            status: 'Your Payments',
        },
        {
            id: 3,
            title: `${UserData?.total_provider_jobs + "Job Request"}`,
            image: yourlocation,
            status: 'Job Requests ',
        },
    ]
    return (
        <>
            <div className="row">
                {data.map((item,index)=>{
                    return(
                        <ProviderProgressBar item={item} type={type}/>
                    )
                })}     
            </div>

            <h3 className="status-text" style={{marginBottom : '14px'}}>Status</h3>
           <div className="job-card-provider">
                <div className="card-Content-provider">
                    <p className="job-title-provider">01 Job On-going</p>
                    <button className="btn btn-primary provider-jobDetails-Btn" 
                        onClick={()=>{history.push('/jobrequest')}}
                    >
                        View Details
                    </button>
                </div>
            </div>  
        </>
    )
}

export default ProviderJobBanner
