import React, { useState } from "react";
import { faCheckCircle, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProviderProfileCard from "../../components/ProviderProfile/ProviderProfileCard";
import UserCard from "../../components/UserCard/UserCard";
import {
  user1,
  user2,
  profile3,
  dollar,
  customerfeedback,
  skills,
} from "../../constant";
import { useHistory, useLocation } from "react-router-dom";
import BookingModal from "../../components/Modal/BookingModal";
import { useSelector, useDispatch } from "react-redux";
import { ImageUrl } from "../../network/ApiUrl";

function ProviderProfile(props) {
  const history = useHistory();
  const location = useLocation();
  console.log(location);

  const userDetail = [
    {
      id: 1,
      title: "Useful Driver ! ",
      name: "Tommy",
      rating: "4.0",
      image: user1,
    },
    {
      id: 2,
      title: "Worthy",
      name: "Amy",
      rating: "4.0",
      image: user2,
    },
    {
      id: 3,
      title: "Useful Driver ! ",
      name: "Tommy",
      rating: "4.0",
      image: user1,
    },
    {
      id: 4,
      title: "Worthy",
      name: "Amy",
      rating: "4.0",
      image: user2,
    },
  ];
  const serviceId = location.state?.service;
  const providerId = location.state?.provider;

  const ProviderServiceData = useSelector(
    (state) => state.AuthReducer.providerServiceData
  );
  console.log(ProviderServiceData);

  const providerProfile = ProviderServiceData?.find(
    (x) => x?.id === providerId
  );
  console.log(providerProfile);
  const [selectedjobDetails, setSelectedjobDetails] = useState(providerProfile);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const Experience = providerProfile?.year_of_exp;
  const Price = providerProfile?.basic_price;
  const Jobs = providerProfile?.total_provider_jobs;

  const data = [
    {
      id: 1,
      title: `${Jobs + " " + "Jobs Done"}`,
      image: skills,
    },
    {
      id: 2,
      title: `${Experience + " " + "Years Exp."}`,
      image: customerfeedback,
    },
    {
      id: 3,
      title: `${"$" + Price + " " + "/ Service"}`,
      image: dollar,
    },
  ];
  console.log(selectedjobDetails);
  return (
    <>
      <div className="Home-main-Container service providerProfile">
        <div className="container">
          <h2 className="dashboard-content-Title">Provider's Profile</h2>
          <p
            className="dashboard-content-SubTitle"
            style={{ marginBottom: "90px" }}
          >
            Home - Services - Profile
          </p>

          <div
            className="back-container"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </div>

          <div className="contact-container">
            <div className="profile-card-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="profile-pic">
                  <img
                    src={`${ImageUrl}${providerProfile?.avatar}`}
                    className="image-fluid image-width"
                    alt=""
                  />
                </div>
              </div>
              <h3 className="user-name">{providerProfile?.full_name}</h3>
              <div className="flexBox-style" style={{ marginTop: "-11px" }}>
                <div className="flexBox-style">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="certified-badge"
                  />
                  <p className="certificate-text">Certified Driver</p>
                </div>
                <div className="flexBox-style">
                  <p className="rating">{providerProfile?.rating}</p>
                  <span className="fa fa-star star-icon"></span>
                </div>
              </div>

              <div className="row">
                {data.map((item, index) => {
                  return <ProviderProfileCard item={item} />;
                })}
              </div>

              <div className="row">
                <div className="col-md-6">
                  <h3 className="status-text" style={{ marginTop: "30px" }}>
                    About
                  </h3>
                  <div className="about-container">
                    <p className="about-para">
                      <p className="rating">{providerProfile?.rating}</p>
                      <p className="rating">{providerProfile?.rating}</p>
                      {providerProfile?.bio
                        ? providerProfile?.bio
                        : "Hi My name is Martha, 5 years experience in Driving and decoded to become an online teacher. I have completed over 100 rides. Hi My name is Martha, 5 years experience in Driving and decoded to become an online teacher. I have completed over 100 rides."}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <h3 className="status-text" style={{ marginTop: "30px" }}>
                    Recent Reviews
                  </h3>
                  <div className="user-card provider-userCard">
                    <div className="user-card-InnerContainer">
                      {userDetail.map((item, index) => {
                        return <UserCard item={item} />;
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 col-sm-4 col-12">
                  <div
                    className="goBack-btn-container"
                    style={{ marginTop: "32px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-warning goBack-Btn"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-12">
                  <div style={{ textAlign: "center", marginTop: "32px" }}>
                    <button
                      type="button"
                      className="btn btn-primary assignJob-Btn"
                      onClick={() => {
                        setIsOpenModal(true);
                      }}
                    >
                      Assign Job
                    </button>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-12">
                  <div className="providerchat-btn-container">
                    <button
                      type="button"
                      className="btn btn-info chat-Btn"
                      onClick={() => {
                        history.push({
                          pathname: `/chatview/${selectedjobDetails?.id}`,
                          state: {
                            data: selectedjobDetails,
                            page: "ProviderProfile",
                          },
                        });
                      }}
                    >
                      Chat
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        serviceId={serviceId}
        providerId={providerId}
      />
    </>
  );
}

export default ProviderProfile;
