import React, { useState, useEffect } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { user1, user2 } from "../../constant";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ChatSideBar from "../../components/Chat/ChatSideBar";
import MessageBox from "../../components/Chat/MessageBox";
import { GetAllChats } from "../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import { ChatUserData } from "../../redux/actions/AuthAction";
import { SpinnerCircular } from "spinners-react";

function ChatView(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const selectedChatData = location?.state?.data;
  const Page = location?.state?.page;
  console.log(Page);
  console.log(selectedChatData);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.AuthReducer.token);
  const Role = useSelector((state) => state.AuthReducer.users.role);
  const userDetail = [
    {
      id: 1,
      name: "Martha K",
      image: user1,
    },
    {
      id: 2,
      name: "Amy",
      image: user2,
    },
    {
      id: 3,
      name: "Tommy",
      image: user1,
    },
    {
      id: 4,
      name: "Albert Froster",
      image: user2,
    },
    {
      id: 5,
      name: "Tommy",
      image: user1,
    },
    {
      id: 6,
      name: "Amy",
      image: user2,
    },
    {
      id: 7,
      name: "Tommy",
      image: user1,
    },
    {
      id: 8,
      name: "Amy",
      image: user2,
    },
    {
      id: 9,
      name: "Amy",
      image: user2,
    },
  ];
  const [selectUserData, setSelectUserData] = useState("");
  const selectUser = (id) => {
    setSelectUserData(id);
  };

  const [chatSideBarData, setChatSideBarData] = useState([]);
  const [customerSideBarData, setCustomerSideBarData] = useState([]);

  const [apiChatId, setApiChatId] = useState("");
  // === Get All Messages  ===
  useEffect(() => {
    GetAllChats(token)
      .then((res) => {
        console.log(res);
        let arr = [];
        let i;
        for (i = 0; i < res?.data?.data?.length; i++) {
          arr.push({
            id: res?.data?.data[i]?.provider?.id,
            name: res?.data?.data[i]?.provider?.first_name,
            fullName: res?.data?.data[i]?.provider?.full_name,
            avatar: res?.data?.data[i]?.provider?.avatar,
            chatId: res?.data?.data[i]?.id,
          });
        }
        let arr2 = [];
        let j;
        for (j = 0; j < res?.data?.data?.length; j++) {
          arr2.push({
            id: res?.data?.data[j]?.customer?.id,
            name: res?.data?.data[j]?.customer?.first_name,
            fullName: res?.data?.data[j]?.customer?.full_name,
            avatar: res?.data?.data[j]?.customer?.avatar,
            chatId: res?.data?.data[j]?.id,
          });
        }
        console.log({ arr });
        console.log({ arr2 });
        setChatSideBarData(arr);
        setCustomerSideBarData(arr2);
        dispatch(ChatUserData(arr));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let itemId = 0;
    if (params?.username) {
      Role === "Provider"
        ? customerSideBarData?.map((item, index) => {
            if (params?.username == item?.id) {
              selectUser(item?.id);
              itemId = item?.id;
            }
          })
        : chatSideBarData?.map((item, index) => {
            if (params?.username == item?.id) {
              selectUser(item?.id);
              itemId = item?.id;
            }
          });
    }
    console.log(
      "location",
      Role,
      params.username,
      itemId,
      customerSideBarData,
      chatSideBarData
    );
  }, [params?.username, chatSideBarData, customerSideBarData]);

  return (
    <>
      <div className="Home-main-Container service chatView">
        <div className="container">
          <h2 className="dashboard-content-Title">Chat</h2>
          <p className="dashboard-content-SubTitle subTitle-margin">
            Home - Services - Profile - Chat
          </p>
          <div
            className="back-container"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </div>

          <div className="contact-container">
            <div className="profile-card-container">
              <div className="row">
                <div className="col-md-4">
                  <div className="chat-user-container">
                    <div className="row">
                      <div className="col-md-12 col-sm-4 mobile-View">
                        {Role === "Provider"
                          ? customerSideBarData?.map((item, index) => {
                              return (
                                <ChatSideBar
                                  userId={params?.username}
                                  item={item}
                                  selectUser={selectUser}
                                />
                              );
                            })
                          : chatSideBarData?.map((item, index) => {
                              return (
                                <ChatSideBar
                                  userId={params?.username}
                                  item={item}
                                  selectUser={selectUser}
                                />
                              );
                            })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <MessageBox
                    selectUserData={selectUserData}
                    setSelectUserData={setSelectUserData}
                    chatSideBarData={chatSideBarData}
                    customerSideBarData={customerSideBarData}
                    selectedChatData={selectedChatData}
                    apiChatId={apiChatId}
                    setApiChatId={setApiChatId}
                    Page={Page}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatView;
