import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { connect } from 'react-redux';
import Alerts from "../src/redux/Alerts/reducer";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import AuthReducer from "./redux/reducers/AuthReducer";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Pusher from "pusher-js";

Pusher.logToConsole = true;
export const pusher = new Pusher("184893220241d66faba6", {
  // app_id: "1302146",
  // secret: "d2427a1f5a5b7833c369",
  cluster: "mt1",
  // encrypted: true,
  forceTLS: true,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
  key: "authType",
  storage: storage,
  whitelist: ["AuthReducer"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    AuthReducer,
    Alerts,
  })
);

const store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(...[thunk]))
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistStore(store)}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
