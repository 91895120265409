import React from "react";
import { faArrowLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import user3 from "../../assets/image/user/user3.png";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ImageUrl } from "../../network/ApiUrl";

function NotificationCard(props) {
  const history = useHistory();
  const Role = useSelector((state) => state.AuthReducer.users.role);
  const { item, page } = props;
  return (
    <>
      <div
        className={
          //   "gray-Color" :
          "notification-Card"
        }
      >
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "86px",
              }}
            >
              <div className="image-container">
                <img
                  src={`${ImageUrl}${item?.user?.avatar}`}
                  className="image-fluid image-width"
                />
              </div>
              <div
                style={{ marginLeft: "12px", marginTop: "16px" }}
                key={item.id}
              >
                <p className="user-Name">{item?.user?.full_name}</p>
                <div style={{ display: "flex" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="notification-user-badge"
                  />
                  <p className="certified-text">
                    {Role === "Customer"
                      ? "Certified Driver"
                      : "Certified User"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 col-sm-5">
            <div className="notificationDetail-container">
              <p className="detail-text">{item?.description}</p>
            </div>
          </div>

          <div className="col-md-3 col-sm-3">
            <div className="notification-btn-container">
              {item.title === "Job" ? (
                <button
                  type="button"
                  className="btn btn-light notification-Btn"
                  onClick={() => {
                    history.push("/activebooking");
                  }}
                >
                  View Details
                </button>
              ) : item.title === "Chat" ? (
                <button
                  type="button"
                  className="btn btn-light notification-Btn"
                  onClick={() => {
                    history.push("/chatview");
                  }}
                >
                  View Details
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-light notification-Btn"
                  onClick={() => {
                    history.push("/ViewOnMap");
                  }}
                >
                  View Details
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationCard;
