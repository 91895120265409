import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBNdvNXlxgOulOogdbZ2qh9GgI6yVtXicw",
  authDomain: "bidit2.firebaseapp.com",
  databaseURL: "https://bidit2-default-rtdb.firebaseio.com",
  projectId: "bidit2",
  storageBucket: "bidit2.appspot.com",
  messagingSenderId: "1003995301492",
  appId: "1:1003995301492:web:8e9abe11935113eaaf3321",
  measurementId: "G-8J9KDHHS2V",
};
// firebase.initializeApp(firebaseConfig);
let firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
export const database = getDatabase(firebaseApp);

// export const database = getFirestore(firebaseApp);

// export const database = firebaseApp.database()

// export const database = () => {
//     firebaseApp.database();
// }
