import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

function ServiceCard(props) {
  const {
    item,
    index,
    // selectedService,setSelectedServiceId,selectedServiceId
  } = props;
  let history = useHistory();
  const Role = useSelector((state) => state.AuthReducer.users.role);
  return (
    <>
      {Role == "Customer" ? (
        <>
          <div className="col-md-2">
            <div
              className="service-card"
              key={index}
              onClick={() => {
                history.push({ pathname: "/driveBooking", state: item?.id });
              }}
              // onClick={()=>{selectedService();setSelectedServiceId(index)}}
            >
              <div className="card-icon">
                <img src={item?.image} className="image-fluid" />
              </div>
              <p className="service-title">{item?.title}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="col-md-2">
            <div
              className="service-card"
              key={index}
              onClick={() => {
                history.push("/service");
              }}
            >
              <div className="card-icon">
                <img src={item?.image} className="image-fluid" />
              </div>
              <p className="service-title">{item?.title}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ServiceCard;
