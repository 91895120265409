import React,{useState} from 'react';
import { MapContainer, TileLayer, Marker, Popup,Tooltip } from 'react-leaflet';


function BookingMap() {
  const position = [51.505, -0.09]
    return (
        <>
       <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>
            Estimated Time : 30 Minutes
          </Popup>
        </Marker>
        {/* <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
          permanent Tooltip for Rectangle
        </Tooltip> */}
      </MapContainer>
        </>
    )
}

export default BookingMap;
