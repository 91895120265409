import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_ERROR,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    SET_PASSWORD_REQUEST,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_ERROR,
    // GET_ME_SUCCESS,
    // LOGOUT_REQUEST,
    // LOGOUT_SUCCESS,
    // LOGOUT_ERROR,
    LOGOUT,
    USER_TOKEN,
    JOBS,
    EDIT_PROFILE,
    All_PAYMENT_METHOD,
    NOTIFICATION,
    CHAT_SEND,
    SERVICES,
    PROVIDERSERVICEDATA,
    USER_MESSAGES,
    CHAT_USERDATA
  } from "../Types";
import {
    forgotPasswordApi,
    postSignIn,
    postSignUp,
    setNewPasswordApi,
    forgotPasswordEmailApi,
    PostChats,
  } from "../../network/Network";
import { toast } from "react-toastify";
import {setError} from "../Alerts/action";
import {responses, responsesLogin} from "../../constant/constFunctions";

  export const login =(data, cb = () => {}) => (dispatch) => {
      dispatch({ type: LOGIN_REQUEST });
      postSignIn(data)
        .then((res) => {
           // console.log("Login",{response : { ...res}});
           //  if(res.data.){
                dispatch({ type: LOGIN_SUCCESS, payload: res?.data?.data?.user });
                dispatch({type: USER_TOKEN, payload: res?.data?.data?.token});
            // }

           dispatch(setError(responsesLogin(res)));
        })
        .catch((err) => {
            console.log("Login",err.response);
          toast.error("Please Enter Correct Email or Password")
            dispatch(setError(responses(err)));
          // toast.error(err?.response?.data?.payload?.message);
          dispatch({ type: LOGIN_ERROR });
        });
    };
  
  export const signUp = (data, cb) => (dispatch) => new Promise(async(resolve , reject) => {
    dispatch({ type: SIGNUP_REQUEST });
    postSignUp(data)
      .then((res) => {
        console.log(res)
          dispatch(setError(responses(res)));
          dispatch({ type: SIGNUP_SUCCESS, payload: res?.data?.data });

        dispatch(setError('success','Successfully Signup!!!'));
        return resolve(true);

      })
      .catch((err) => {
          console.log("Error",err.response);
          dispatch(setError(responses(err)));
          toast.error(err?.response?.data?.payload?.message);
          return reject(false);
      })
      .finally(() => {
        dispatch({ type: SIGNUP_ERROR });
      });
  });
  
  export const forgotPassword = (data) => (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    forgotPasswordApi(data)
      .then((res) => {
        dispatch({ type: FORGOT_PASSWORD_SUCCESS });
        console.log(res)
        // toast.success("Email has been sent to your email address");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.payload?.message);
        dispatch({ type: FORGOT_PASSWORD_ERROR });
      });
  };

  export const forgotPasswordEmail = (data) => (dispatch) => {
    console.log(data)
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    forgotPasswordEmailApi(data)
      .then((res) => {
        dispatch({ type: FORGOT_PASSWORD_SUCCESS });
        toast.success("Email has been sent to your email address");
        console.log(res)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.payload?.message);
        dispatch({ type: FORGOT_PASSWORD_ERROR });
      });
  };
  
  
  export const setPassword = (password, resetToken, cb) => (dispatch) => {
    dispatch({ type: SET_PASSWORD_REQUEST });
    setNewPasswordApi({ password }, resetToken)
      .then((res) => {
        toast.success("Your password has been reset");
        dispatch({ type: SET_PASSWORD_SUCCESS });
        // cb();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.payload?.message);
        dispatch({ type: SET_PASSWORD_ERROR });
      });
  };
 
  export const logout = (payload) => {
    return {
        type: LOGOUT,
        payload
    }
  }

  export const GetJobs = (payload) => {
    return {
        type: JOBS,
        payload
    }
  }

  export const EditProfileData = (payload) => {
    return {
        type: EDIT_PROFILE,
        payload
    }
  }

  export const AllPaymentMethod = (payload) => {
    return {
        type: All_PAYMENT_METHOD,
        payload
    }
  }

  export const NotificationApi = (payload) => {
    return {
        type: NOTIFICATION,
        payload
    }
  }

  
  export const postMessages =(data,token, cb = () => {}) => (dispatch) => {
    PostChats(data,token)
      .then((res) => {
        console.log(res)
        dispatch({ type: CHAT_SEND, payload: res?.data?.data});
      })
      .catch((err) => {
        console.log(err)
      });
  };

  export const UserMessages = (payload) => {
    return {
        type: USER_MESSAGES,
        payload
    }
  }

  export const AllServices = (payload) => {
    return {
        type: SERVICES,
        payload
    }
  }

  export const StoreProviderServices = (payload) => {
    return {
        type: PROVIDERSERVICEDATA,
        payload
    }
  }

  export const ChatUserData = (payload) => {
    return {
        type: CHAT_USERDATA,
        payload
    }
  }




