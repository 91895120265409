// Define api end points here

export const API = "/api";
export const AUTH = "/auth";
export const SIGNUP = "/register";
export const LOGIN = "/login";
export const FROGOT_PASSWORD = "/forgot";

export const SERVICES = "/services";
export const UPDATE_PROFILE = "/update-profile";
export const RESEST = "/reset-password";

export const JOBS = "/jobs";
export const NOTIFICATION = "/notifications";
export const PROVIDERS = "/providers";
export const RATINGS = "/ratings";
export const PAYMENT_METHODS = "/payment-methods";

export const USER_SERVICES = "/user-services";

export const CHATS = "/chats";

export const CONTACTUS = "/storeContactUs";

export const GETREVIEW = "/getReview";

// export const LOGOUT = "/logout";

// export const SET = "/set";
// export const FORM = "/form";
// export const CREATE = "/create";
// export const CLIENT = "/client";
// export const GET = "/get";
// export const DROPDOWN = "/dropdown";
// export const USER = "/user";
// export const UPLOAD = "/upload";
// export const SESSION = "/session";
