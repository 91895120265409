import React,{useState} from 'react';
import { Formik, Form } from 'formik';
import { TextField } from '../../components/TextField/TextField';
import * as Yup from 'yup';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from "react-router-dom";
import { doodle,card} from "../../constant";
import { AddPaymentMethods } from '../../network/Network';
import { useSelector, useDispatch } from 'react-redux';
import { ImageUrl } from '../../network/ApiUrl';
import {loadStripe} from '@stripe/stripe-js';
import '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import StripeForm from '../../components/StripeForm/StripeForm';


function NewPaymentMethod() {
    let history = useHistory();
    const[newCard, setNewCard] = useState([])
    const[loading, setLoading] = useState(false)
    const[tokenVisa, setTokenVisa] = useState(false)
    /* global Stripe */
    // const stripe = Stripe('pk_test_TYooMQauvdEDq54NiTphI7jx');
    // const stripe = useStripe();
    // const elements = useElements();
    const validate = Yup.object({
        cardName: Yup.string()
          .max(15, 'Must be 15 characters or less')
          .required('Required'),
        cardNumber: Yup.string()
          .max(16, 'Must be 16 Digit')
          .required('Required'),
        expireyDate: Yup.string()
          .required('Expiry date is required'),
        cvv: Yup.string()
          .required('cvv date is required'),
      })
      const token = useSelector(state => state.AuthReducer.token);
      const UserData = useSelector(state => state.AuthReducer.users);

      const AddNewCard = (values)=>{
        setNewCard(values)   
      }

      const stripeTokeHandler = async () =>{
        // var param = {}
        // param.card = {
        //     number: newCard.cardNumber,
        //     expirey_Date: newCard.expireyDate,
        //     cvv: newCard.cvv,
        // }
        // console.log(param)
        //   await stripe.tokens.create(param, function(err,token){
        //         if(err){
        //             console.log(err)
        //         }
        //         if(token){
        //             console.log(token)
        //             setTokenVisa(token)
        //             alert(token)
        //         }
        //         else
        //         console.log("some thing wrong")
        //     })

        const stripe = await loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');
        console.log(stripe)
        const result = await stripe.createToken('account', {
            card: {
               name: newCard.cardName,
                cardNumber: newCard.cardNumber,
                expiry: newCard.expireyDate,
                cvv: newCard.cvv,
              },
            })
            console.log("res",result)
        }
      
    return (
        <>
            <div className="Home-main-Container service new-Payment">
                <div className="container">
                    <h2 className="dashboard-content-Title">Edit Payment</h2>
                    <p className="dashboard-content-SubTitle" style={{ marginBottom: '76px'}}>Profile - New Payment Method</p>
                    <div 
                        className="back-container"
                        onClick={()=>{history.goBack()}}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="back-icon"/>
                    </div>
                    <div className="contact-container">
                        <div className="profile-card-container">
                            <div className="flexBox-style">
                                <div className="profile-pic">
                                <img src={`${ImageUrl}${UserData?.avatar}`} className="image-fluid image-width" /> 
                                </div>
                                <div className="doodle-container">
                                    <img src={doodle} className="image-fluid" />
                                </div>
                            </div>
                            <h3 className="user-name">John Doe</h3>                    
                            <Formik
                                initialValues={{
                                    cardName: '',
                                    cardNumber: '',
                                    expireyDate: '',
                                    cvv: '',
                                }}
                                validationSchema={validate}
                                onSubmit={values => {
                                    setNewCard(values)
                                    console.log(values)
                                    
                                    stripeTokeHandler()
                                    // const elementsData = {
                                    //     name : values.cardName,
                                    //     number: values.cardNumber,
                                    //     expireyDate: values.expireyDate,
                                    //     cvv: values.cvv
                                    // }
                                   
                                    // stripe.createToken(cardElement,data).then(function(result) {
                                    //     // Handle result.error or result.token
                                    //     console.log(result)
                                    //     setVisa_token(result)
                                    //   });
                                    // stripe
                                    //     .createSource( elementsData)
                                    //     .then(function(result) {
                                    //         // Handle result.error or result.source
                                    //     });

                                    let data ={
                                        // token: 'tok_1Jw5yVKzMNWu8IhYMyzbHUXb'
                                        token: tokenVisa
                                    }



                                    AddPaymentMethods(data,token)
                                    .then((res) => {
                                        console.log(res?.data?.data)                                         
                                    })
                                    .catch((err) => {
                                        console.log(err)                        
                                    })
                                }}
                            >
                                {formik => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="flexBox-style-space">
                                                    <p className="sub-Title">Basic Information</p>
                                                </div>
                                                <div className="user-Detail-container">
                                                    <div style={{ padding: '38px'}}>
                                                        <div className="row">
                                                            <div className="col-md-9">     
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <TextField label="Name on Card" name="cardName" placeholder="Your Name on Card" type="text" />
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <TextField label="Card Number" name="cardNumber" placeholder="Your 16 Digit Card Number" type="number" />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <TextField label="Expiry Date" name="expireyDate" placeholder="Date / Year" type="date" />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <TextField label="CVV" name="cvv" placeholder="***" type="number" />
                                                                    </div>
                                                                </div>          
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="paymentCard-vector">
                                                                    <img src={card} className="image-fluid"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 save-container">
                                                    <button                            
                                                        className="btn btn-primary savePayment-Btn" 
                                                        type="submit" disabled={loading}
                                                        // onClick={()=>onToken()}
                                                    >
                                                        {loading ? "loading.." : "Save"}
                                                    </button>
                                                </div>
                                                                                               
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>                                           
                        </div>
                    </div>                 
                </div>
            </div>
        </>
    )
}

export default NewPaymentMethod;
