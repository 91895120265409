import {
   ALERTS, SET_ALERTS
} from "../Types";
import {
    forgotPasswordApi,
    postSignIn,
    postSignUp,
    setNewPasswordApi,
    forgotPasswordEmailApi,
    PostChats,
    // logoutApi,
  } from "../../network/Network";
import { toast } from "react-toastify";

export const makeAlert  = (obj) =>{
    return {
        type : ALERTS,
        payload : obj,
    }
}

export const resetAlerts  = () =>{
    return {
        type : SET_ALERTS,
        payload : null,
    }
}

export const setError = (arr) => {
    return (dispatch) => {
        dispatch(makeAlert(arr));
        setTimeout(()=>{
            dispatch(resetAlerts())
        },50000);
    }
}





