import React , { useEffect } from 'react';
import { ImageUrl } from '../../network/ApiUrl';
                         
function ChatSideBar(props) {
    const {item,selectUser} = props;
    console.log("item id", item, props.userId);

    return (
        <>
            <div className="row user-chatCard" 
                id={`user${item?.id}`} 
                onClick={()=>{selectUser(item?.id)}}
            >
                <div className="col-md-4">
                    <div className="sidebar-user">
                    <img src={`${ImageUrl}${item?.avatar}`} className="image-fluid image-width" /> 
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="sidebar-user-name">{item?.name}</div>
                </div>
            </div>  
        </>
    )
}

export default ChatSideBar
