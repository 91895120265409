import React from 'react';
import { Formik, Form } from 'formik';
import { TextField } from '../../components/TextField/TextField';
import * as Yup from 'yup';
import logo from "../../assets/image/logo.png";
import { forgotPassword } from '../../redux/actions/AuthAction';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

export const NewPassword = () => {
  const validate = Yup.object({
    email: Yup.string()
    .email('Email is invalid')
    .required('Email is required'),
    otp: Yup.string()
    .required('OTP is required'),
    newPassword: Yup.string()
      .min(6, 'Password must be at least 6 charaters')
      .required('Password is required'),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref('password'), null], 'Password must match')
    //   .required('Confirm password is required'),
  })
  const dispatch = useDispatch()
  let history = useHistory();
  return (
      <>
        <div className="Login forgotPassword">
            <div className="container">
                <div className="main-Container">
                    <div className="row">
                        <div className="logo-container logo">
                              <img src={logo} className="image-fluid" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="login-title">Forgot Password</h2>
                        </div>
                    </div>
                </div>
               
                <div className="row">
                <Formik
                    initialValues={{
                        email: '',
                        otp: '',
                        newPassword: '',
                        // confirmPassword: '',
                    }}
                    validationSchema={validate}
                    onSubmit={values => {
                        console.log(values)
                    
                            let data = {
                                email:values.email,
                                otp:values.otp,
                                password: values.password,
                            }
                            console.log(data)
                            dispatch(forgotPassword(data))
                            history.push("/login");
                    }}
                >
                {formik => (
                    <div>
                    <Form>
                            <div className="col-md-12">
                                <TextField label="Email" name="email" placeholder="Enter Your Email" type="email" />
                            </div>
                            <div className="col-md-12">
                                <TextField label="OTP" name="otp" placeholder="Enter Your OTP" type="text" />
                            </div>
                            <div className="col-md-12">
                                <TextField label="New Password" name="newPassword" placeholder="Enter a new password" type="password" />
                            </div>
                            {/* <div className="col-md-12">
                                <TextField label="Confirm Password" name="confirmPassword" placeholder="Confirm a new password" type="password" />
                            </div> */}
                             <div className="col-md-12 confirmPass-container">
                                <button className="btn btn-primary Login-Btn" type="submit">Confirm</button>
                            </div>
                    </Form>
                    </div>
                    )}
                </Formik>
                   
               </div>
            </div>
        </div>
    </>
  )
}

