import React, { useState, useEffect } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useLocation } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import MuiStepper from "../../components/Stepper/MuiStepper";
import BookingMap from "../../components/Map/BookingMap";
import GoogleMapTest from "../../components/Map/GoogleMapTest";
import { getDatabase, ref, onValue, child, get } from "firebase/database";
import FirbaseApp, { database } from "../../network/FireBase";
import { useSelector, useDispatch } from "react-redux";
import firebaseApp from "../../network/FireBase";

function ViewOnMap(props) {
  const history = useHistory();
  const location = useLocation();
  const providerData = location.state;
  console.log(location.state);
  const Role = useSelector((state) => state.AuthReducer.users?.role);

  useEffect(() => {
    if (Role === "Customer") {
      const query = ref(
        database,
        `/provider/latLng/${providerData?.customer?.id}`
      );
      get(query, `/provider/latLng/${providerData?.customer?.id}`)
        .then((snapshot) => {
          console.log("snp", snapshot.val());
        })
        .catch((error) => {
          console.error(error);
        });

      // get(query, `/provider/latLng/${providerData?.customer?.id}`).then(
      //   (item) => {
      //     const items = item.docs?.map((doc) => doc.data());
      //     console.log("items", items);
      //   }
      // );

      // query.onSnapShot((snp) => {
      //   const item = [];
      //   snp.forEach((doc) => {
      //     item.push(doc.data());
      //     console.log(doc.data());
      //   });
      // });

      // query.once("value").then(function (snapshot) {
      //   snapshot.forEach(function (childSnapshot) {
      //     var key = childSnapshot.key;
      //     var childData = childSnapshot.val();
      //     console.log(childData);
      //   });
      // });

      // const dbRef = ref(
      //   database,
      //   `/provider/latLng/${providerData?.customer?.id}`
      // );
      // onValue(dbRef, (snapshot) => {
      //   console.log("snp", snapshot);
      //   snapshot.forEach((childSnapshot) => {
      //     console.log("snp");
      //     const childKey = childSnapshot.key;
      //     console.log(childKey);
      //     const childData = childSnapshot.val();
      //     console.log(childData);
      //     // ...
      //   });
      // });

      // var dbref = ref(
      //   database,
      //   `/provider/latLng/${providerData?.customer?.id}`
      // );
      // dbref
      //   .once("value")
      //   .then((snapshot) => {
      //     var result = snapshot.child.val();
      //     console.log("result", result);
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
    }
  }, []);

  return (
    <>
      <div className="Home-main-Container service bookingDetails">
        <div className="container">
          <h2 className="dashboard-content-Title">Map</h2>
          <p
            className="dashboard-content-SubTitle"
            style={{ marginBottom: "29px" }}
          >
            Bookings - Active Bookings - Details - View on Map
          </p>

          <div
            className="back-container"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </div>

          <div style={{ marginBottom: "15px" }}>
            <StyledEngineProvider injectFirst>
              <MuiStepper />
            </StyledEngineProvider>
          </div>

          <div className="contact-container">
            <div
              className="profile-card-container"
              style={{ padding: "12px 12px" }}
            >
              <div className="map-custom-container">
                {/* <BookingMap /> */}
                <GoogleMapTest providerData={providerData} />
              </div>
              <div className="flexBox-style mapButton-mobView">
                <div style={{ textAlign: "center", marginTop: "32px" }}>
                  <button
                    type="button"
                    className="btn btn-primary assignJob-Btn"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Go Back
                  </button>
                </div>
                <div style={{ marginTop: "32px", float: "right" }}>
                  <button
                    type="button"
                    className="btn btn-info chat-Btn"
                    onClick={() => {
                      history.push("/chatview");
                    }}
                  >
                    Chat
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewOnMap;
