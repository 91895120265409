import React,{useState,useEffect} from 'react';
import ActiveBookingCard from '../../components/BookingCard/ActiveBookingCard';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllJOBS, UpdateJOB } from '../../network/Network';
import { GetJobs } from '../../redux/actions/AuthAction';
import { SpinnerCircular } from 'spinners-react';

function ActiveBooking(props) {
    const dispatch = useDispatch()
    const data=[
        {
            id:1,
            name: 'Martha Karolin',
            certificate: 'Certified Driver',
            detail: 'Aug 20, 9.30 AM - 30 Mins - $20',
            button: 'View Details',
            rating: '4.0',
            status: 'Waiting for Approval',
        },
    ]
    const[activeBooking,setActiveBooking] = useState(false)
    const[loading,setLoading] = useState(false)
    const Jobs = useSelector(state => state.AuthReducer.jobs);
    const token = useSelector(state => state.AuthReducer.token);
    const[ jobData, setJobData] = useState([])
    const[ selectedJobId, setSelectedJobId] = useState('')
    

// == Update Job API ==
    const approveBooking = () => {
        setActiveBooking(!activeBooking)
        let status;
        if(activeBooking) {
            status ="Accepted"
        }
        else {
            status ="Pending"
        }

        const data = {
            status : status,
            }
            console.log(data)
        let id = selectedJobId;
        console.log(id)
        UpdateJOB(id,data,token)
            .then((res) => {          
                console.log(res)
                dispatch(GetJobs(res?.data?.data))           
            })
            .catch((err) => {
                console.log(err) 
            })
    }

// == get Jobs API ==
    useEffect(() => {
        setLoading(true)
        GetAllJOBS(token)
        .then((res) => {
            setTimeout(() => {
                setLoading(false)   
                console.log(res)
                setJobData(res?.data?.data)
                dispatch(GetJobs(res?.data?.data))
            }, 800);            
        })
        .catch((err) => {
            console.log(err)
            setLoading(false)   
        })
    }, [])

    console.log(jobData)
    return (
        <>
            <div className="Home-main-Container service activeBooking">
                <div className="container">
                    <h2 className="dashboard-content-Title">Active Bookings </h2>
                    <p className="dashboard-content-SubTitle" style={{ marginBottom: '76px'}}>Bookings - Active Bookings</p>
                   
                    <div className="contact-container">
                        <div className="profile-card-container">
                            {loading ? (
                                <div className="loader-container">
                                 <SpinnerCircular 
                                     size="80px"
                                     color="#5C3DA4"
                                 />
                                </div>
                            ) : (
                                jobData?.map((item,index)=>{
                                    return(
                                        <>
                                        {item?.status === "Pending" || item?.status === "Accepted" ? (
                                            <>
                                                 <ActiveBookingCard  
                                                    item={item} 
                                                    page={"activeBooking"}
                                                    activeBooking={activeBooking}
                                                    approveBooking={approveBooking}
                                                    setSelectedJobId={setSelectedJobId}
                                                    selectedJobId={selectedJobId}
                                                />
                                            </>
                                        ) : (null)}
                                       
                                        </>
                                    )
                                })
                            )}
                                           
                        </div>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default ActiveBooking;
