import React,{useState,useEffect} from 'react';
import { Formik, Form } from 'formik';
import { TextField } from '../../components/TextField/TextField';
import * as Yup from 'yup';
import { Link, useHistory } from "react-router-dom"; 
import logo from "../../assets/image/logo.png";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {signUp} from '../../redux/actions/AuthAction';

export const Signup = () => {
  const validate = Yup.object({
    firstName: Yup.string()
      .max(15, 'Must be 15 characters or less')
      .required('Required'),
    lastName: Yup.string()
      .max(15, 'Must be 15 characters or less')
      .required('Required'),
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 charaters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match')
      .required('Confirm password is required'),
  })

  let history = useHistory();
  const dispatch = useDispatch()
  const [role, setRole] =useState("Provider")
  const [provider, setProvider] =useState(true)
  const [user, setUser] =useState(false)
  const [loading, setLoading] =useState(false)

  const toggleHandler = () =>{
    setProvider(!provider)
    setUser(!user)
}

  return (
      <>
        <div className="Login">
            <div className="container">
                <div className="main-Container">
                    <div className="row">
                        <div className="logo-container logo">
                              <img src={logo} className="image-fluid" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="login-title">Sign Up as</h2>
                        </div>
                        <div className="col-md-6">
                            <div className="button-container">
                                <button type="button" 
                                    className="btn btn-primary customer-Btn" 
                                    onClick={()=>{setRole("Customer");toggleHandler()}}
                                    disabled={user}
                                >
                                    Customer
                                </button>
                                <button type="button" 
                                    className="btn btn-primary customer-Btn"
                                    onClick={()=>{setRole("Provider");toggleHandler()}}
                                    disabled={provider}
                                >
                                    Provider
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </div>
               
                <div className="row">
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                        confirmPassword: '',
                    }}
                    validationSchema={validate}
                    onSubmit={values => {
                        setLoading(true)
                        console.log(values)
                        if(!values.firstName && !!values.lastName  && !values.email && !values.password && !values.confirmPassword && !role ){
                            toast.error("please enter correct email and password")
                            setLoading(false)
                        }
                        else{
                            let data = {
                                first_name: values.firstName, 
                                last_name: values.lastName, 
                                email: values.email,
                                password: values.password,
                                password_confirmation: values.confirmPassword,
                                role: role
                            };
                            console.log("Payload",data)
                            dispatch(signUp(data))
                            setLoading(false)
                            history.push("/login");
                        }
                    }}
                >
                {formik => (
                    <div>
                    <Form>
                            <div className="col-md-12">
                                <TextField label="First Name" name="firstName" placeholder="Enter your First Name" type="text" />
                            </div>
                            <div className="col-md-12">
                                <TextField label="Last Name" name="lastName" placeholder="Enter your Last Name" type="text" />
                            </div>
                            <div className="col-md-12">
                                <TextField label="Email" name="email" placeholder="Enter your Email" type="email" />
                            </div>
                            <div className="col-md-12">
                                <TextField label="Password" name="password" placeholder="Enter your password" type="password" />
                            </div>
                            <div className="col-md-12">
                                <TextField label="Confirm Password" name="confirmPassword" placeholder="Re-enter your password" type="password" />
                            </div>
                             <div className="col-md-12 LoginBtn-container">
                                <button className="btn btn-primary Login-Btn" 
                                    type="submit" disabled={loading}
                                >
                                    {loading ? "Loading.." : "SignUp"}
                                </button>
                            </div>
                            <div className="col-md-12">
                                <p className="newAccount-Text"><Link to="/login">Already have account? Login</Link></p>
                            </div>
                    </Form>
                    </div>
                    )}
                </Formik>
                   
               </div>
            </div>
        </div>
    </>
  )
}

