import React,{useState} from 'react';
import { faCheckCircle,faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from "react-router-dom";
import { Rating, RatingView } from 'react-simple-star-rating'
import { complete, profile3 } from "../../constant";
import { BookingFinishedRating } from '../../network/Network';
import { useSelector, useDispatch } from 'react-redux';

function BookingFinished(props) {
    const history = useHistory();
    const token = useSelector(state => state.AuthReducer.token);
    const [rating, setRating] = useState(0) // initial rating value
    const [feedBack, setFeedBack] = useState("")
    const [jobId, JobId] = useState(1) 

    const [loading, setLoading] = useState(false)
    const handleRating = (rate) => {
      setRating(rate)
    }

    const postFeedbackReview = () =>{
        let data = {
            job_id: jobId, 
            rating: rating, 
            feedback: feedBack,     
        };
        BookingFinishedRating(data,token)
        .then((res) => {
            console.log(res?.data?.data)
            // dispatch(EditProfileData(res?.data?.data))                            
            // const response = res?.data?.data;
            // setLoading(false)    
        })
        .catch((err) => {
            console.log(err)
            // setLoading(false)
        })
    }

    return (
        <>
            <div className="Home-main-Container service bookingFinished">
                <div className="container">
                    <h2 className="dashboard-content-Title">Job Finished!</h2>
                    <p className="dashboard-content-SubTitle" 
                        style={{ marginBottom: '79px'}}
                    >
                        Bookings - Active Bookings - Details - Finish
                    </p>

                    <div 
                        className="back-container"
                        onClick={()=>{history.push('/drivebooking')}}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="back-icon"/>
                    </div>

                    <div className="contact-container">
                        <div className="profile-card-container">
                            <div className="flexBox-style">
                                <div className="profile-pic">
                                    <img src={profile3} className="image-fluid image-width" alt=""/>
                                </div>
                            </div>
                            <h3 className="user-name">Martha Karolin</h3> 
                            <div className="flexBox-style" style={{marginTop: '-11px'}}>
                                <div className="flexBox-style">
                                    <FontAwesomeIcon icon={faCheckCircle} className="certified-badge"/>
                                    <p className="certificate-text">Certified Driver</p>
                                </div>
                                <div className="flexBox-style">              
                                    <p className="rating">4.0</p>
                                    <span className="fa fa-star star-icon"></span>
                                </div>
                            </div>
                            <p className="complete-status">Completed</p>                         

                            <div className="row">                               
                                <div className="col-md-12">
                                    <div className="user-card-content">
                                        <div className="user-card-InnerContainer">
                                            <div className="row">     
                                                <div className="col-md-9">
                                                    <p className="complete-title"><span style={{color:'#5C3DA4'}}>Congratulations!</span> Martha has completed the job</p>
                                                    <p className="user-card-title">Rate The Driver</p>
                                                    <div className="review-Card"> 
                                                        <Rating onClick={handleRating} ratingValue={rating} /* Rating Props */ />
                                                    </div>
                                                
                                                    <p className="user-card-title">Give Reviews</p>
                                                    <div className="review-input">
                                                        <textarea type="text" 
                                                            placeholder="Give some good words to the Driver" 
                                                            value={feedBack}
                                                            onChange={(e)=>setFeedBack(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3" >
                                                    <div className="complete-vector">
                                                        <img src={complete} className="image-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className="flexBox-style">
                                <div style={{textAlign:'center', marginTop: '10px'}}>
                                    <button type="button" 
                                        className="btn btn-primary assignJob-Btn"
                                        onClick={()=>{postFeedbackReview()}}
                                        disabled={loading}
                                    >
                                       {loading ? "loading.." : "Submit"} 
                                    </button>
                                </div>  
                            </div>  
                        </div>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default BookingFinished;
