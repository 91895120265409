import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { TextField } from "../../components/TextField/TextField";
import * as Yup from "yup";
import { faArrowLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { profile2, editVector } from "../../constant";
import { UpdateProfile } from "../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import { ImageUrl } from "../../network/ApiUrl";
import { EditProfileData } from "../../redux/actions/AuthAction";

function EditProfile(props) {
  let history = useHistory();
  let dispatch = useDispatch();
  const token = useSelector((state) => state.AuthReducer.token);
  const UserData = useSelector((state) => state.AuthReducer.users);
  const validate = Yup.object({
    firstName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    lastName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    // email: Yup.string()
    //   .email('Email is invalid')
    //   .required('Email is required'),
    phone: Yup.string()
      .min(6, "Password must be at least 6 charaters")
      .required("Password is required"),
    address: Yup.string()
      .min(6, "Password must be at least 6 charaters")
      .required("Password is required"),
  });
  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadLoading(true);
  };

  useEffect(() => {}, [UserData]);

  return (
    <>
      <div className="Home-main-Container service editProfile">
        <div className="container">
          <h2 className="dashboard-content-Title">Edit Profile</h2>
          <p
            className="dashboard-content-SubTitle"
            style={{ marginBottom: "76px" }}
          >
            Profile - Profile Settings
          </p>
          <div
            className="back-container"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </div>

          <div className="contact-container">
            <div className="profile-card-container">
              <div className="flexBox-style">
                <div className="profile-pic">
                  {uploadLoading ? (
                    <img src={fileupload} className="image-fluid image-width" />
                  ) : (
                    <img
                      src={`${ImageUrl}${
                        fileupload ? fileupload : UserData?.avatar
                      }`}
                      className="image-fluid image-width"
                    />
                  )}
                </div>

                <div className="edit-container">
                  <FontAwesomeIcon icon={faPen} className="edit-pen-icon" />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple="false"
                  />
                </div>
              </div>
              <h3 className="user-name">{UserData?.full_name}</h3>
              <Formik
                initialValues={{
                  firstName: UserData?.first_name,
                  lastName: UserData?.last_name,
                  email: "",
                  phone: UserData?.phone,
                  address: UserData?.address,
                }}
                // validationSchema={validate}
                onSubmit={(values) => {
                  setLoading(true);
                  console.log(values);
                  let data = new FormData();
                  data.append("first_name", values?.firstName);
                  data.append("last_name", values?.lastName);
                  data.append("avatar", newFile);
                  data.append("phone", values?.phone);
                  data.append("address", values?.address);

                  UpdateProfile(data, token)
                    .then((res) => {
                      setLoading(false);
                      setUploadLoading(false);
                      console.log(res.data.data);
                      dispatch(EditProfileData(res?.data?.data));
                      history.push("/profile");
                    })
                    .catch((err) => {
                      console.log(err);
                      setLoading(false);
                      setUploadLoading(false);
                    });
                }}
              >
                {(formik) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="flexBox-style-space">
                          <p className="sub-Title">Basic Information</p>
                        </div>
                        <div className="user-Detail-container">
                          <div
                            className="editContainer-padding"
                            style={{ padding: "38px" }}
                          >
                            <div className="row">
                              <div className="col-md-9">
                                <div className="row">
                                  <div className="col-md-6">
                                    <TextField
                                      label="First Name"
                                      name="firstName"
                                      placeholder="first Name"
                                      type="text"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <TextField
                                      label="Last Name"
                                      name="lastName"
                                      placeholder="Last Name"
                                      type="text"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <TextField
                                      label="Email"
                                      name="email"
                                      placeholder={UserData?.email}
                                      type="email"
                                      disabled
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <TextField
                                      label="Phone"
                                      name="phone"
                                      placeholder="Phone number"
                                      type="number"
                                    />
                                  </div>
                                  <div className="col-md-12">
                                    <TextField
                                      label="Address"
                                      name="address"
                                      placeholder="Lorem Ipsum is simply dummy text of the printing & typesetting industry."
                                      type="text"
                                      style={{ height: "64px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="editVector">
                                  <img
                                    src={editVector}
                                    className="image-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 SaveChanges-container">
                          <button
                            className="btn btn-primary saveChanges-Btn"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Loading.." : "Save Changes"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProfile;
