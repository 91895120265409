import React from "react";
import { faTrashAlt, faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AddPaymentMethod(props) {
  const { item, index, DeletePaymentMethod, paymentLoading } = props;
  console.log("data", item);
  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <p className="user-Title">{item?.card_brand}</p>
          <p className="user-data">************{item?.card_last4}</p>
        </div>
        {index === 0 ? (
          <div className="payment-card-default">
            <label className="radioBtn-Label">Default</label>
            <input type="radio" name="optradio" checked />
          </div>
        ) : (
          <div className="payment-card-options">
            <label className="radioBtn-Label">Make this Default</label>
            <input type="radio" name="optradio" />
          </div>
        )}

        <div style={{ float: "right", marginLeft: "9px" }}>
          <FontAwesomeIcon
            icon={faTrashAlt}
            style={{ color: "#707070" }}
            className="paymentDelete"
            onClick={() => {
              DeletePaymentMethod(item?.id, index);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default AddPaymentMethod;
