import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProviderJobBanner from "../components/JobBanner/ProviderJobBanner";
import UserJobBanner from "../components/JobBanner/UserJobBanner";
import UserCard from "../components/UserCard/UserCard";
import ServiceCard from "../components/ServiceCard/ServiceCard";
import {
  airplane,
  cargoship,
  deliveryman,
  deliverytruck,
  locationpin,
  speechbubble,
  user1,
  user2,
  offerBanner,
} from "../constant";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Device } from "@capacitor/device";
import {
  GetAllNotifications,
  GetAllReview,
  GetAllServices,
  GetAllUserServices,
} from "../network/Network";
import { AllServices, NotificationApi } from "../redux/actions/AuthAction";
import { database } from "../network/FireBase";
import firebaseApp from "../network/FireBase";
import { ref, set, push } from "firebase/database";

function Home(props) {
  const Role = useSelector((state) => state.AuthReducer.users?.role);
  const token = useSelector((state) => state.AuthReducer.token);
  const UserData = useSelector((state) => state.AuthReducer.users);
  const Notifications = useSelector((state) => state.AuthReducer.notifications);
  const [allServiceData, setAllServiceData] = useState([]);
  const dispatch = useDispatch();
  let history = useHistory();
  const [notification, setNotification] = useState([]);
  const [coords, setCoords] = useState({});
  const [recentReviewData, setRecentReviewData] = useState([]);

  const data = [
    {
      id: 1,
      title: "Driver",
      image: locationpin,
    },
    {
      id: 2,
      title: "Delivery",
      image: deliveryman,
    },
    {
      id: 3,
      title: "Shipping",
      image: deliverytruck,
    },
    // {
    //     id:4,
    //     title: 'Cargo',
    //     image: cargoship,
    // },
    // {
    //     id:5,
    //     title: 'Air',
    //     image: airplane,
    // },
    // {
    //     id:6,
    //     title: 'Instant',
    //     image: speechbubble,
    // },
  ];
  const userDetail = [
    {
      id: 1,
      title: "Useful Driver ! ",
      name: "Tommy",
      rating: "4.0",
      image: user1,
    },
    {
      id: 2,
      title: "Worthy",
      name: "Amy",
      rating: "4.0",
      image: user2,
    },
    {
      id: 3,
      title: "Useful Driver ! ",
      name: "Tommy",
      rating: "4.0",
      image: user1,
    },
    {
      id: 4,
      title: "Worthy",
      name: "Amy",
      rating: "4.0",
      image: user2,
    },
  ];
  const logDeviceInfo = async () => {
    const info = await Device.getId();
    console.log(info);
  };

  useEffect(() => {
    logDeviceInfo();
  }, []);

  //  == Get Notification ==
  useEffect(() => {
    GetAllNotifications(token)
      .then((res) => {
        console.log(res);
        setNotification(res?.data?.data);
        dispatch(NotificationApi(notification));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [notification]);

  // == Get All Services ==
  useEffect(() => {
    if (Role === "Customer") {
      GetAllServices(token)
        .then((res) => {
          console.log("CUSTOMER", res);
          setAllServiceData(res?.data?.data);
          dispatch(AllServices(res?.data?.data));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      GetAllUserServices(token)
        .then((res) => {
          console.log("PROVIDER", res);
          setAllServiceData(res?.data?.data);
          // setUserServiceData(res?.data?.data)
          dispatch(AllServices(res?.data?.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const FireBaseFunc = (info) => {
    const commentsRef = ref(database, "/provider/latLng/" + UserData?.id);
    // const newPostRef = push(commentsRef);
    push(commentsRef, {
      lat: info.coords.latitude,
      lng: info.coords.longitude,
    })
      .then(() => {
        console.log("setted");
      })
      .catch((err) => {
        console.log("no setted", err);
      });
  };

  // == Geo Location ==
  useEffect(() => {
    if (Role === "Provider") {
      navigator.geolocation.watchPosition(
        (info) => {
          console.log("watching", info);
          FireBaseFunc(info);
        }
        // (err) => console.log("coordsLat", err),
        // {
        //   enableHighAccuracy: false,
        //   timeout: 5,
        // }
      );
    }
  }, []);

  // == All Reviews ==
  useEffect(() => {
    GetAllReview(token)
      .then((res) => {
        console.log("res", res);
        setRecentReviewData(res?.data?.data);
        // dispatch(AllServices(res?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="Home-main-Container">
        <div className="container">
          <h2 className="dashboard-content-Title">Home</h2>
          <p className="dashboard-content-SubTitle">Home</p>
          {Role == "Provider" ? (
            <ProviderJobBanner />
          ) : (
            <>
              <UserJobBanner />
            </>
          )}

          <div className="flexBox-style-space">
            <h3
              className="status-text"
              style={{ marginTop: "34px", marginBottom: "14px" }}
            >
              Services
            </h3>
            <Link
              to="/service"
              className="seeAll-text"
              style={{ marginTop: "34px", marginBottom: "14px" }}
            >
              see all
            </Link>
          </div>

          <div className="row">
            {data?.map((item, index) => {
              return <ServiceCard item={item} index={index} />;
            })}
          </div>

          {Role == "Provider" ? null : (
            <>
              <div className="row" style={{ marginBottom: "10px" }}>
                <div className="col-md-6 col-sm-12">
                  <h3 className="status-text offer-margin">Offers for you</h3>
                  <div className="offer-banner">
                    <img src={offerBanner} className="image-fluid" alt="" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <h3 className="status-text offer-margin">Recent Reviews</h3>
                  <div className="user-card">
                    <div className="user-card-InnerContainer">
                      {userDetail?.map((item, index) => {
                        return <UserCard item={item} />;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Home;
