import "./App.css";
import "./assets/css/Style.css";
import "./assets/css/Contact.css";
import "./assets/css/Booking.css";
import "./assets/css/DashboardLayout.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthRoutes from "./routes/AuthRoutes";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
// import * as Icons from "@fortawesome/fontawesome-free-solid"
import FullLayout from "./Views/FullLayout";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import Alert from "./Views/Alert";

function App() {
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  return (
    <>
      {isLogin ? (
        <>
          <FullLayout />
          <ToastContainer />
        </>
      ) : (
        <>
          <AuthRoutes />
          <ToastContainer />
        </>
      )}
      <Alert />
    </>
  );
}

export default App;
