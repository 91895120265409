import React from 'react'
import {BrowserRouter as Router, Route} from "react-router-dom";
import { Login } from "../Views/AuthViews/Login";
import { Signup } from "../Views/AuthViews/Signup";
import { ForgotPassword } from "../Views/AuthViews/ForgotPassword";
import { NewPassword } from "../Views/AuthViews/NewPassword";
import { Redirect } from 'react-router';

function AuthRoutes() {
    return (
        <>
            <Router>
                <Route exact path='/login' component={Login} />
                <Route exact path='/signup' component={Signup} />
                <Route exact path='/forgotpassword' component={ForgotPassword} />
                <Route exact path='/newpassword' component={NewPassword} />
                <Redirect to="/login" />
            </Router>
            
        </>
    )
}

export default AuthRoutes;

