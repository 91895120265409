import React from 'react';

function ProviderProfileCard(props) {
    const {item} = props;
    return (
        <>
           <div className="col-md-4" key={item.id}>
               <div className="provider-card">
                    <div>
                        <img src={item.image} className="image-fluid" />
                    </div>
                    <h3 className="provider-card-title">{item.title}</h3>
                </div>
            </div>  
        </>
    )
}

export default ProviderProfileCard
