import React,{useState} from 'react';
import { faArrowLeft,faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from "react-router-dom";
import {profile2} from "../../constant";

function RequestConfirmation(props) {
    let history = useHistory();
    return (
        <>
            <div className="Home-main-Container service req-confirm">
                <div className="container">
                    <h2 className="dashboard-content-Title">Request Confirmation</h2>
                    <p className="dashboard-content-SubTitle" style={{ marginBottom: '76px'}}>Home - Services - Profile - Book - Details - Payment - Request</p>
                
                    <div className="contact-container">
                        <div className="profile-card-container">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div className="profile-pic">
                                    <img src={profile2} className="image-fluid image-width" alt="" />
                                </div>
                            </div>
                            <h3 className="user-name">Martha Karolin</h3>
                            <div className="flexBox-style" style={{marginTop: '-11px'}}>
                                <div className="flexBox-style">
                                    <FontAwesomeIcon icon={faCheckCircle} className="certified-badge"/>
                                    <p className="certificate-text">Certified Driver</p>
                                </div>
                                <div className="flexBox-style">              
                                    <p className="rating">4.0</p>
                                    <span className="fa fa-star star-icon"></span>
                                </div>    
                            </div>
                            <div style={{padding: '10px 82px'}}>
                                <div className="status-card"> 
                                    <p className="status-text">Wait for the Driver to Confirm your Request. </p>
                                </div>
                            </div>  
                        
                            <div className="col-md-12 save-container">
                                <button type="button" 
                                    className="btn btn-primary savePayment-Btn" type="submit"
                                    onClick={()=>{history.push('/')}}
                                >Back to Home</button>
                            </div>
                            <div className="text-center mt-4">
                                <Link to="/drivebooking" className="goToBooking">Go to Bookings</Link>
                            </div>
                         

                        </div>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default RequestConfirmation;
