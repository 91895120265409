import React, { useState } from "react";
import { Link } from "react-router-dom";
import { faArrowLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { profile2 } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import { UpdateJOB } from "../../network/Network";
import { GetJobs } from "../../redux/actions/AuthAction";

function DeliveryConfirmation(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector((state) => state.AuthReducer.token);
  const DeliveryStatus = location.state?.status;
  const jobRequestItem = location.state?.item;

  // == Update Job API ==
  const approveBooking = () => {
    const data = {
      status: DeliveryStatus,
    };
    console.log(data);
    let id = jobRequestItem?.id;
    console.log(id);
    console.log(data);
    UpdateJOB(id, data, token)
      .then((res) => {
        console.log(res);
        dispatch(GetJobs(res?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="Home-main-Container service req-confirm">
        <div className="container">
          <h2 className="dashboard-content-Title">Delivery Confirmation</h2>
          <p
            className="dashboard-content-SubTitle"
            style={{ marginBottom: "76px" }}
          >
            Home - Job Requests - Details - Confirmation
          </p>

          <div
            className="back-container"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </div>

          <div className="contact-container">
            <div className="profile-card-container">
              <div className="flexBox-style">
                <div className="profile-pic">
                  <img
                    src={profile2}
                    className="image-fluid image-width"
                    alt=""
                  />
                </div>
              </div>
              <h3 className="user-name">John Doe</h3>
              <div className="flexBox-style" style={{ marginTop: "-11px" }}>
                <div className="flexBox-style">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="certified-badge"
                  />
                  <p className="certificate-text">Certified User</p>
                </div>
              </div>
              <div className="delivery-card">
                <div className="status-card">
                  <p className="status-text">
                    {DeliveryStatus == "Accepted"
                      ? "Do you want to Accept the User Request?"
                      : "Are you sure you want to reject User Request?"}
                  </p>
                </div>
              </div>

              <div className="col-md-12 save-container">
                {DeliveryStatus == "Accepted" ? (
                  <button
                    className="btn btn-primary savePayment-Btn"
                    type="submit"
                    style={{ width: "230px" }}
                    onClick={() => {
                      approveBooking();
                    }}
                  >
                    Accept & Inform User
                  </button>
                ) : (
                  <button
                    className="btn btn-danger savePayment-Btn"
                    type="submit"
                    style={{ width: "230px", backgroundColor: "#D91025" }}
                    onClick={() => {
                      approveBooking();
                    }}
                  >
                    Reject and Inform User
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeliveryConfirmation;
