import React, { useState, useEffect } from "react";
import NotificationCard from "../../components/NotificationCard/NotificationCard";
import { GetAllNotifications } from "../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import { NotificationApi } from "../../redux/actions/AuthAction";
// import { pusher } from "../../index";

function Notification(props) {
  const token = useSelector((state) => state.AuthReducer.token);
  const UserDataRedux = useSelector((state) => state.AuthReducer.users);
  const Notifications = useSelector((state) => state.AuthReducer.notifications);
  const dispatch = useDispatch();
  const [notification, setNotification] = useState([]);
  const data = [
    {
      id: 1,
      name: "Martha Karolin",
      certificate: "Certified Driver",
      detail: "Martha Karolin has Delivered your Parcel",
      button: "View Details",
    },
    {
      id: 2,
      name: "Martha Karolin",
      certificate: "Certified Driver",
      detail:
        "Martha Karolin has picked your Parcel and she is on her way to deliver",
      button: "View on Map",
    },
    {
      id: 3,
      name: "Martha Karolin",
      certificate: "Certified Driver",
      detail: "Martha Karolin is on her way to Pickup your Parcel",
      button: "Chat with her",
    },
    {
      id: 4,
      name: "Martha Karolin",
      certificate: "Certified Driver",
      detail: "Martha Karolin has accept your Job Request",
      button: "Go to Bookings",
    },
  ];
  useEffect(() => {
    setNotification(Notifications);
  }, [notification]);

  console.log("Notifications", Notifications);

  // notification Pusher
  // const channel = pusher.subscribe(`notification.${UserDataRedux?.id}`);
  // useEffect(() => {
  //     channel.bind("App\\Models\\Job", (data) => {
  //     console.log("cldata", data);
  //     // if (data?.msg && data?.msg?.chat_id == chatId) {
  //     //     console.log("event called", data?.msg);
  //     // }
  //     // setProfileMessagesData((prevMsg) => [data?.msg, ...prevMsg]);
  //     // console.log("pm", profileMessagesData);
  //     });
  // }, []);

  return (
    <>
      <div className="Home-main-Container service notification">
        <div className="container">
          <h2 className="dashboard-content-Title">Your Notifications</h2>
          <p
            className="dashboard-content-SubTitle"
            style={{ marginBottom: "76px" }}
          >
            Notifications
          </p>

          <div className="contact-container">
            <div className="profile-card-container">
              {notification?.map((item, index) => {
                return <NotificationCard item={item} page={"notification"} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
