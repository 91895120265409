import React,{useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';

function UserJobBanner() {
    const Jobs = useSelector(state => state.AuthReducer.jobs);
   
    return (
        <>
             <h3 className="status-text" style={{marginBottom : '14px'}}>Status</h3>
           <div className="job-card">
                <div className="card-Content">
                    <p className="job-title">01 Job On-going</p>
                    <Link to="/jobrequest" className="job-detail-Text">Click to view Details</Link>
                </div>
            </div> 
        </>
    )
}

export default UserJobBanner
