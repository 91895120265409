import React from "react";

function UserCard(props) {
  const { item } = props;
  return (
    <>
      <div className="row mt-3" key={item.id}>
        <div className="col-md-2 col-sm-2 col-2">
          <div className="user-image">
            <img src={item?.image} className="image-fluid image-width" alt="" />
          </div>
        </div>
        <div className="col-md-8 col-sm-8 col-8">
          <p className="user-card-title">{item?.title}</p>
          <p className="user-card-name">{item?.name}</p>
        </div>
        <div className="col-md-2 col-sm-2 col-2 flexBox-style">
          <p className="recent-rating-no">{item?.rating}</p>
          <span className="fa fa-star star-icon"></span>
        </div>
      </div>
    </>
  );
}

export default UserCard;
