import React,{useState,useEffect} from 'react';
import { Formik, Form } from 'formik';
import { TextField } from '../../components/TextField/TextField';
import * as Yup from 'yup';
import { Link, useHistory } from "react-router-dom"; 
import logo from "../../assets/image/logo.png";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {login} from '../../redux/actions/AuthAction';
import { date } from 'yup/lib/locale';
import { Device } from '@capacitor/device';

export const Login = () => {
    const token = useSelector(state => state.AuthReducer.token);
  const validate = Yup.object({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 charaters')
      .required('Password is required'),
  })
  let history = useHistory();
  const dispatch = useDispatch()
  const [loading, setLoading] =useState(false)
  const [loginType, setLoginType] =useState("Customer")
  const [provider, setProvider] =useState(false)
  const [user, setUser] =useState(true)
  const [deviceId, setDeviceId] =useState("")
  
    
//   const LoginHandler = () =>{
//       const regex =
//       /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    
//       if(!email && !password || (regex.test(email) === false) ){
//           toast.error("please enter correct email and password")
//       }
//       else{
//           toast.success("success")
//           let data = [{email:email,password:password}]
//           dispatch(login(data))
//           history.push("/");
//       }
//   }

const toggleHandler = () =>{
    setProvider(!provider)
    setUser(!user)
}

const logDeviceInfo = async () => {
    const device_Id = await Device?.getId();
    setDeviceId(device_Id)
    console.log(device_Id);
  };

  useEffect(() => {
    logDeviceInfo()
  }, [])

  return (
      <>
        <div className="Login">
            <div className="container">
                <div className="main-Container">
                    <div className="row">
                        <div className="logo-container logo">
                              <img src={logo} className="image-fluid" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="login-title">Sign in as</h2>
                        </div>
                        <div className="col-md-6">
                            <div className="button-container">
                                <button type="button" 
                                    className="btn btn-primary customer-Btn" 
                                    onClick={()=>{setLoginType("Customer");toggleHandler()}}
                                    disabled={user}
                                >
                                    Customer
                                </button>
                                {/* provider-Btn */}
                                <button type="button" 
                                    className="btn btn-primary customer-Btn"
                                    onClick={()=>{setLoginType("Provider");toggleHandler()}}
                                    disabled={provider}
                                >
                                    Provider
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </div>
               
                <div className="row">
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={validate}
                    onSubmit={values => {
                        setLoading(true)
                        if(!values.email && !values.password && !loginType ){
                            toast.error("please enter correct email and password")  
                            setLoading(false)
                        }
                        else{
                            let data = {
                                email:values.email,
                                password:values.password,
                                device_id: deviceId?.uuid,
                                role : loginType,
                            }
                            console.log(data)
                            setTimeout(() => {
                                dispatch(login(data))
                                setLoading(false)   
                                if(token) {
                                    history.push("/");
                                }      
                              }, 600);                         
                        }                     
                    }}
                >
                {formik => (
                    <div>
                    <Form>
                            <div className="col-md-12">
                                <TextField label="Email" name="email" placeholder="Enter your email" type="email" />
                            </div>
                            <div className="col-md-12">
                                <TextField label="Password" name="password" placeholder="Enter your password" type="password" />
                            </div>
                            <div className="col-md-12">
                                <p className="forget-Text"><Link to="/forgotpassword">Forget Password?</Link></p>
                            </div>
                             <div className="col-md-12 LoginBtn-container">
                                <button className="btn btn-primary Login-Btn" 
                                    type="submit" disabled={loading}
                                >
                                    {loading ? "Loading.." : "Login"}
                                </button>
                            </div>
                            <div className="col-md-12">
                                <p className="newAccount-Text">
                                    <Link to="/signup">Create New Account</Link>
                                </p>
                            </div>
                            {/* <div className="col-md-12">
                                <p className="loginAdmin-Text"><Link to="">Login as ADMIN</Link></p>
                            </div> */}
                    </Form>
                    </div>
                    )}
                </Formik>
                   
               </div>
            </div>
        </div>
    </>
  )
}

