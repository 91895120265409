import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { user3 } from "../../constant";
import moment from "moment";
import { ImageUrl } from "../../network/ApiUrl";

function JobRequestCard(props) {
  let history = useHistory();
  const { item, page, activeBooking, approveBooking } = props;
  const [rating, setRating] = useState(0); // initial rating value

  const handleRating = (rate) => {
    setRating(rate);
  };
  console.log(item);
  return (
    <>
      {item.status === "Pending" ? (
        <>
          <div className="jobRequest">
            <div className="row">
              <div className="col-md-5 col-sm-5">
                <div className="flexBox-style jobcard-container">
                  <div className="image-container">
                    <img
                      src={`${ImageUrl}${item?.customer?.avatar}`}
                      className="image-fluid image-width"
                    />
                  </div>
                  <div
                    style={{ marginLeft: "12px", marginTop: "16px" }}
                    key={item?.id}
                  >
                    <p className="user-Name" style={{ color: "#fff" }}>
                      {item?.customer?.full_name}
                    </p>
                    <div className="flexBox-align">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="booking-certified-badge"
                        style={{ color: "#fff" }}
                      />
                      <p className="certified-text" style={{ color: "#fff" }}>
                        Certified User
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="jobRequest-date-container">
                  <p className="detail-text" style={{ color: "#fff" }}>
                    {moment(item?.created_at).format("MMM Do YYY, h:mm a")} -{" "}
                    {item?.status}
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="viewDetails-container">
                  <div className="flexBox-style-space jobBtn-mobile-view">
                    <button
                      type="button"
                      className="btn btn-primary job-btn"
                      onClick={() => {
                        history.push({
                          pathname: "/jobrequestdetails",
                          state: item,
                        });
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default JobRequestCard;
