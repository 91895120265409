import React, { useState } from "react";
import { contact1 } from "../constant";
import { useSelector, useDispatch } from "react-redux";
import { PostContactus } from "../network/Network";
import { toast } from "react-toastify";

function ContactUs(props) {
  const [query, setQuery] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const Token = useSelector((state) => state.AuthReducer.token);

  // Post Contactus
  const AssignJob = () => {
    setLoading(true);
    let data = {
      title: query,
      message: message,
    };
    PostContactus(data, Token)
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully send");
        setQuery("");
        setMessage("");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setQuery("");
        setMessage("");
      });
  };

  return (
    <>
      <div className="Home-main-Container contact">
        <div className="container">
          <h2 className="dashboard-content-Title">Contact Us</h2>
          <p className="dashboard-content-SubTitle">Contact</p>
          <div className="contact-container">
            <div className="contact-card">
              <p className="query-title">Query Title</p>
              <div className="query-input">
                <textarea
                  type="text"
                  placeholder="Your Query here"
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                />
              </div>

              <div style={{ display: "flex" }}>
                <div>
                  <p className="query-title" style={{ marginTop: "20px" }}>
                    Your Message here
                  </p>
                  <div className="message-input">
                    <textarea
                      type="text"
                      placeholder="Type your Message here"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                  </div>
                  <p className="sub-message">
                    After Submitting, Admin will soon respond to your Query.
                    Have Patience. Thankyou.*
                  </p>
                </div>
                <div>
                  <div className="image-container">
                    <img src={contact1} className="image-fluid" alt="" />
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <button
                  type="button"
                  className="btn btn-primary submit-Btn"
                  onClick={() => AssignJob()}
                  disabled={loading}
                >
                  {loading ? "Loading.." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
