import "../App.css";
import "../assets/css/Style.css";
import "../assets/css/Contact.css";
import "../assets/css/DashboardLayout.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Routes from "../routes/Routes";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "../../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
import NavBar from "../components/NavBar/NavBar";
import Sidebar from "../components/Sidebar/Sidebar";
import { BrowserRouter as Router } from "react-router-dom";

import React, { useState, useRef } from "react";
import logo from "../assets/image/logo.png";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";

import { findDOMNode } from "react-dom";

function FullLayout() {
  const $a = document.querySelector("#sidebar-toggle");
  const $wrapper = document.querySelector("#wrapper");

  // $button.addEventListener('click', (e) => {
  const toggleNavBar = (e) => {
    e.preventDefault();
    $wrapper?.classList?.toggle("toggled");
  };

  // const toggle = useRef(null);

  // const handleSwap = () => {
  //   const el = findDOMNode(toggle);
  //   $(el).slideToggle();
  // }

  const Role = useSelector((state) => state.AuthReducer.users?.role);
  const Notification = useSelector((state) => state.AuthReducer?.notifications);
  const [notification, setNotification] = useState(false);

  return (
    <>
      <Router>
        <div id="wrapper">
          {/* <NavBar /> */}
          <div id="navbar-wrapper">
            <nav className="navbar navbar-inverse">
              <div className="container-fluid">
                <div className="dashboard-Logo">
                  <img src={logo} className="image-fluid" />
                </div>
                <div>
                  <p className="navbar-title">
                    {Role == "Provider"
                      ? "Provider Dashboard"
                      : "User Dashboard"}
                  </p>
                </div>

                <div className="navbar-header navBar-CustomToggle">
                  <a
                    className="navbar-brand"
                    id="sidebar-toggle"
                    onClick={(e) => toggleNavBar(e)}
                  >
                    <i className="fa fa-bars"></i>
                  </a>
                </div>

                <div className="navbar-header">
                  <Link
                    to="/notification"
                    className="navbar-brand"
                    id="sidebar-toggle"
                  >
                    <FontAwesomeIcon
                      icon={faBell}
                      className="back-icon"
                      onClick={() => setNotification(!notification)}
                    />
                    <div className="notification-container">
                      <p className="notification-num">{Notification?.length}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </nav>
          </div>

          <aside id="sidebar-wrapper">
            <Sidebar />
          </aside>

          <section id="content-wrapper">
            <Routes />
          </section>
        </div>
      </Router>
    </>
  );
}

export default FullLayout;
