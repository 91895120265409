import React, { useState } from "react";
import {
  faCheckCircle,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useLocation } from "react-router-dom";
import { profile2, profile3 } from "../../constant";
import { ImageUrl } from "../../network/ApiUrl";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { UpdateJOB } from "../../network/Network";
import { GetJobs } from "../../redux/actions/AuthAction";

function JobRequestDetails(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedjobRequestDetails = location?.state;
  console.log({ selectedjobRequestDetails });

  return (
    <>
      <div className="Home-main-Container service bookingDetails jobDetails">
        <div className="container">
          <h2 className="dashboard-content-Title">View Details</h2>
          <p
            className="dashboard-content-SubTitle"
            style={{ marginBottom: "76px" }}
          >
            Home - Job Requests - Details
          </p>

          <div
            className="back-container"
            onClick={() => {
              history.push("/drivebooking");
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </div>

          <div className="contact-container">
            <div className="profile-card-container custom-profile-card">
              <div className="flexBox-style">
                <div className="profile-container">
                  <img
                    src={`${ImageUrl}${selectedjobRequestDetails?.provider?.avatar}`}
                    className="image-fluid image-width"
                    alt=""
                  />
                  {/* <img src={profile3} className="image-fluid image-width" alt=""/> */}
                </div>
                <FontAwesomeIcon icon={faArrowRight} className="right-icon" />
                <div className="profile-container">
                  <img
                    src={`${ImageUrl}${selectedjobRequestDetails?.customer?.avatar}`}
                    className="image-fluid image-width"
                    alt=""
                  />
                </div>
              </div>
              <h3 className="user-name" style={{ marginTop: "12px" }}>
                {selectedjobRequestDetails?.customer?.full_name}
              </h3>
              <div className="flexBox-style" style={{ marginTop: "-11px" }}>
                <div className="flexBox-style">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="certified-badge"
                  />
                  <p className="certificate-text" style={{ marginLeft: "9px" }}>
                    Certified User
                  </p>
                </div>
              </div>

              <p className="paid-service">
                ${selectedjobRequestDetails?.price} Paid for the service
              </p>

              <div className="row">
                <div className="col-md-5">
                  <h3 className="status-text" style={{ marginTop: "30px" }}>
                    Summary
                  </h3>
                  <div className="about-container">
                    <div className="flexBox-style-space summary-container">
                      <p className="user-card-title">Date</p>
                      <p className="user-card-data">
                        {moment(selectedjobRequestDetails?.created_at).format(
                          "MMM Do, h:mm a"
                        )}
                      </p>
                    </div>
                    <div className="flexBox-style-space summary-container">
                      <p className="user-card-title">Duration</p>
                      <p className="user-card-data">
                        {selectedjobRequestDetails?.duration}
                      </p>
                    </div>
                    <div className="flexBox-style-space summary-container">
                      <p className="user-card-title">Price</p>
                      <p className="user-card-data">
                        ${selectedjobRequestDetails?.price}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <h3 className="status-text" style={{ marginTop: "30px" }}>
                    Pick & Drop Details
                  </h3>
                  <div className="user-card-content">
                    <div className="user-card-InnerContainer">
                      <p className="user-card-title">Pick Address</p>
                      <p className="user-card-para">
                        {selectedjobRequestDetails?.lat}
                      </p>
                      <p
                        className="user-card-title"
                        style={{ marginTop: "38px" }}
                      >
                        Drop Address
                      </p>
                      <p className="user-card-para">
                        {selectedjobRequestDetails?.lng}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="reject-container">
                <p
                  className="reject-btn"
                  onClick={() => {
                    history.push({
                      pathname: "/deliveryconfirmation",
                      state: {
                        status: "Rejected",
                        item: selectedjobRequestDetails,
                      },
                    });
                  }}
                >
                  Reject
                </p>
              </div>

              <div className="flexBox-style">
                <div style={{ textAlign: "center", marginTop: "14px" }}>
                  <button
                    type="button"
                    className="btn btn-success accept-btn"
                    onClick={() => {
                      history.push({
                        pathname: "/deliveryconfirmation",
                        state: {
                          status: "Accepted",
                          item: selectedjobRequestDetails,
                        },
                      });
                    }}
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobRequestDetails;
