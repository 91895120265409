import React,{useState} from 'react';
import ProviderActiveBookingDetails from '../ProviderProfile/ProviderActiveBookingDetails';
import UserActiveBookingDetails from '../UserActiveBookingDetails/UserActiveBookingDetails';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

function ActiveBookingDetails(props) {
    const Role = useSelector(state => state.AuthReducer.users.role);
    const location = useLocation()
    console.log({location})
    const JobId = location?.state?.jobId;
    const page = location?.state?.page;
    return (
        <>
        {Role === "Customer" ? (
                <UserActiveBookingDetails JobId={JobId} page={page}/>
            ) : (
                <ProviderActiveBookingDetails JobId={JobId} page={page}/>
            )}
        </>
    )
}

export default ActiveBookingDetails;
