import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faMicrophone,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import BookingModal from "../Modal/BookingModal";
import InputEmoji from "react-input-emoji";
import { useSelector, useDispatch } from "react-redux";
import AllMessagesContainer from "./AllMessagesContainer";
import { ImageUrl } from "../../network/ApiUrl";
import { postMessages, UserMessages } from "../../redux/actions/AuthAction";
import { GetAllChats, GetChats } from "../../network/Network";
import { pusher } from "../../index";

function MessageBox(props) {
  const {
    selectUserData,
    setSelectUserData,
    chatSideBarData,
    customerSideBarData,
    selectedChatData,
    apiChatId,
    setApiChatId,
    Page,
  } = props;

  const dispatch = useDispatch();
  const Role = useSelector((state) => state.AuthReducer.users.role);
  const UserDataRedux = useSelector((state) => state.AuthReducer.users);
  const token = useSelector((state) => state.AuthReducer.token);
  const ChatUserData = useSelector((state) => state.AuthReducer.ChatUserData);
  console.log("userRedux", UserDataRedux);
  const providerData = chatSideBarData?.find((x) => x.id === selectUserData);
  const customerData = customerSideBarData?.find(
    (x) => x.id === selectUserData
  );

  const providerSelectedChatData = chatSideBarData?.find(
    (x) => x.id === selectedChatData?.id
  );
  const customerSelectedChatData = customerSideBarData?.find(
    (x) => x.id === selectedChatData?.id
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [messageTyping, setMessageTyping] = useState("");
  const [mediaFile, setMediaFile] = useState("");

  const [postMessage, setPostMessage] = useState([sendMessageData]);
  const [sendMessageData, setSendMessageData] = useState([]);
  const [profileMessagesData, setProfileMessagesData] = useState([]);
  const [messageType, setMessageType] = useState("text");

  const [chatId, setChatId] = useState("");

  console.log({ selectedChatData });

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    // if (messageType?.length > 0) {
    //   setMessageType("text");
    // }
    if (file?.type === "image/png") {
      setMessageType("photo");
    } else {
      setMessageType("attachment");
      // file?.type === "text/plain" || file?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    }
    reader.onloadend = () => {
      setMediaFile(file);
      console.log("media", file);
      // setFileupload(reader.result)
    };
    reader.readAsDataURL(file);
  };
  console.log("message Type", messageType);

  const handleOnEnter = () => {
    console.log(mediaFile);
    let Providerid = selectUserData ? selectUserData : selectedChatData?.id;
    let Customerid = selectUserData ? selectUserData : selectedChatData?.id;
    let data = new FormData();
    data.append("customer_id", Customerid);
    data.append("provider_id", Providerid);
    data.append("message_type", messageType);
    data.append("message", messageTyping);
    data.append("attachment", mediaFile);
    console.log(data);
    dispatch(postMessages(data, token));
    setMediaFile(" ");
    setMessageType("text");
    setMessageTyping(" ");
  };

  console.log({ selectedChatData });

  // === Get Messages ===
  useEffect(() => {
    let id = providerData?.chatId
      ? providerData?.chatId
      : customerData?.chatId
      ? customerData?.chatId
      : chatSideBarData[0]?.chatId
      ? chatSideBarData[0]?.chatId
      : customerSideBarData[0]?.chatId;
    console.log(id);
    setChatId(id);
    GetChats(id, token)
      .then((res) => {
        console.log("msg all", res);
        // profileMessagesData?.slice(index, 1);
        setProfileMessagesData(res?.data?.data?.messages);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectUserData, selectedChatData, profileMessagesData]);

  // Messages Pusher
  const channel = pusher.subscribe(`message.${UserDataRedux?.id}`);
  useEffect(() => {
    channel.bind("App\\Events\\Message", (data) => {
      console.log("cldata", data);
      if (data?.msg && data?.msg?.chat_id == chatId) {
        console.log("event called", data?.msg);
      }
      setProfileMessagesData((prevMsg) => [data?.msg, ...prevMsg]);
      console.log("pm", profileMessagesData);
    });
  }, [profileMessagesData]);

  return (
    <>
      <div className="chat-messenger-container">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="profile-pic">
            {/* <img src={`${ImageUrl}${selectedChatData?.avatar ? selectedChatData?.avatar : selectedChatData?.customer?.avatar  ? selectedChatData?.customer?.avatar : Role === "Provider" ? customerData?.avatar : providerData?.avatar }`} className="image-fluid image-width" />       */}
            {/* <img src={`${ImageUrl}${selectedChatData?.avatar ? selectedChatData?.avatar : Role === "Provider" ? customerData?.avatar : providerData?.avatar }`} className="image-fluid image-width" />       */}
            <img
              src={`${ImageUrl}${
                Role === "Provider"
                  ? customerData?.avatar
                  : providerData?.avatar
              }`}
              className="image-fluid image-width"
            />
          </div>
        </div>
        <div className="flexBox-style">
          {/* <h3 className="user-name">{selectedChatData?.full_name ? selectedChatData?.full_name : selectedChatData?.customer?.full_name ? selectedChatData?.customer?.full_name : Role === "Provider" ? customerData?.fullName : providerData?.fullName }</h3> */}
          <h3 className="user-name">
            {selectedChatData?.customer?.full_name
              ? selectedChatData?.customer?.full_name
              : Role === "Provider"
              ? customerData?.fullName
              : providerData?.fullName}
          </h3>
          <div className="online"></div>
        </div>

        <div className="flexBox-style" style={{ marginTop: "-11px" }}>
          <div className="flexBox-style">
            <FontAwesomeIcon icon={faCheckCircle} className="certified-badge" />
            <p className="certificate-text">
              {Role === "Customer" ? "Certified Driver" : "Certified User"}
            </p>
          </div>
          <div className="flexBox-style">
            <p className="rating">4.0</p>
            <span className="fa fa-star star-icon"></span>
          </div>
        </div>

        <AllMessagesContainer
          UserDataRedux={UserDataRedux}
          setPostMessage={setPostMessage}
          postMessage={postMessage}
          profileMessagesData={profileMessagesData}
          selectedChatData={selectedChatData}
          customerData={customerData}
          providerData={providerData}
        />

        <div className="chat-box-mainContainer row">
          <div className={`${Role === "Customer" ? "col-md-8" : "col-md-12"}`}>
            <div className="typing-Container">
              <div className="flexBox-style-space">
                <div className="chat-profile">
                  <img
                    src={`${ImageUrl}${UserDataRedux?.avatar}`}
                    className="image-fluid image-width"
                  />
                </div>
                <div
                  className={`${
                    Role === "Customer" ? "message-box" : "message-box2"
                  }`}
                >
                  <InputEmoji
                    value={messageTyping}
                    onChange={setMessageTyping}
                    cleanOnEnter
                    onEnter={handleOnEnter}
                    placeholder="Type your Message here"
                    className="inputEmoji"
                  />
                </div>
                {/* <FontAwesomeIcon
                  icon={faMicrophone}
                  className="certified-badge"
                /> */}

                <div className="upload-btn-wrapper">
                  <FontAwesomeIcon
                    icon={faPaperclip}
                    className="certified-badge"
                    style={{ position: "absolute" }}
                  />
                  <input
                    type="file"
                    accept="audio/*|video/*|image/*|media_type"
                    onChange={handleImageUpload}
                    multiple="false"
                  />
                </div>
              </div>
            </div>
          </div>
          {Role === "Customer" ? (
            <div className="col-md-4">
              <div style={{ marginTop: "14px" }}>
                <button
                  className="btn btn-primary assignJob-Btn"
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                >
                  Assign Job
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <BookingModal setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal} />
    </>
  );
}

export default MessageBox;
