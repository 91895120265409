import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { faArrowLeft,faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import {profile2} from "../../constant";

function RideFinished(props) {
    let history = useHistory();
    return (
        <>
            <div className="Home-main-Container service req-confirm rideFinished">
                <div className="container">
                    <h2 className="dashboard-content-Title">Ride Finished!</h2>
                    <p className="dashboard-content-SubTitle" style={{ marginBottom: '76px'}}>Bookings - Active Bookings - Details - Finish</p>
                
                    <div className="contact-container">
                        <div className="profile-card-container">
                            <p className="finished-text">Congratulations! Ride has been Finished.</p>
                            <div style={{ padding: '0px 34px'}}>
                                <div className="paymetText-container">
                                    <p className="paymentcard-text">$20 has been added in your wallet. Check Payments Section</p>
                                </div>           
                            </div>
                           
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div className="profile-pic-container">
                                    <img src={profile2} className="image-fluid image-width" alt="" />
                                </div>
                            </div>
                            <h3 className="user-name-finished">Martha Karolin</h3>
                            <div className="flexBox-style" style={{marginTop: '-11px'}}>
                                <div className="flexBox-style">
                                    <FontAwesomeIcon icon={faCheckCircle} className="certified-badge"/>
                                    <p className="certificate-text">Certified User</p>
                                </div>
                                
                            </div>
                            <div style={{padding: '10px 82px'}}>
                        
                                    <p className="status-text">Service provided to John Doe!</p>

                            </div>  

                            <p className="goToBooking">Go to Payments</p>
                            <div className="col-md-12 save-container">
                                <button type="button" 
                                    className="btn btn-primary savePayment-Btn" type="submit"
                                    onClick={()=>{history.push('/')}}
                                >Back to Home</button>
                            </div>

                        </div>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default RideFinished;
