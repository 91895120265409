import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import IconMasterCard from "../../assets/image/Icon_MasterCard.png";
import { useHistory } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { useSelector, useDispatch } from "react-redux";
import { POSTJOBS } from "../../network/Network";

function BookingModal(props) {
  const { isOpenModal, setIsOpenModal, serviceId, providerId } = props;
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [customTime, setCustomTime] = useState("");
  const [pickUpDetails, setPickUpDetails] = useState("");
  const [dropAddress, setDropAddress] = useState("");
  const [cvv, setCvv] = useState("");
  const history = useHistory();
  // const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [bookingToken, setBookingToken] = useState("");

  const onToken = async (token) => {
    console.log(token?.id);
    await setBookingToken(token?.id);
    history.push("/requestconfirmation");
  };

  const AssignJob = async () => {
    let data = {
      service_id: serviceId,
      status: "Pending",
      provider_id: providerId,
      address: pickUpDetails,
      destination_address: dropAddress,
    };
    POSTJOBS(data, Token)
      .then((res) => {
        setPickUpDetails("");
        setDropAddress("");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setPickUpDetails("");
        setDropAddress("");
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
        className="custom-modal modal-width"
      >
        <h2 className="modal-title">Booking Details</h2>
        <hr />
        <div className="row" style={{ marginTop: "24px" }}>
          <div className="col-md-5">
            <p className="pickup-text">Select day</p>
            <div className="select-border months">
              <input
                type="month"
                placeholder="20 / 08/ 2021"
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-5">
            <p className="pickup-text">Select date</p>
            <div className="select-border date">
              <input
                type="date"
                placeholder="20 / 08/ 2021"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <p className="pickup-text">Select Time</p>
            <div className="time">
              <input
                type="time"
                placeholder="10 :30 pm"
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-5">
            <p className="pickup-text" style={{ marginLeft: "66px" }}>
              Custom Time
            </p>
            <div className="flexBox-style">
              <p className="or-text">or</p>
              <div className="custome-time">
                <input
                  type="text"
                  placeholder="Write your Own"
                  value={customTime}
                  onChange={(e) => {
                    setCustomTime(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pick-MainContainer">
          <p className="pickup-text">Pick Address</p>
          <div className="pickUp-container">
            <textarea
              type="text"
              placeholder="Enter Pickup Details"
              value={pickUpDetails}
              onChange={(e) => {
                setPickUpDetails(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="mt-3">
          <p className="pickup-text">Drop Address</p>
          <div className="pickUp-container">
            <textarea
              type="text"
              placeholder="Enter Drop Details"
              value={dropAddress}
              onChange={(e) => {
                setDropAddress(e.target.value);
              }}
            />
          </div>
        </div>

        <div>
          <h2 className="modal-title mt-3">Payment Details</h2>
          <hr />

          <div className="row">
            <div className="col-md-12">
              <div className="flexBox-style-space">
                <p className="pickup-text" style={{ marginLeft: "18px" }}>
                  Payment
                </p>
                <p className="card-setting">Change card in Settings</p>
              </div>
              <div className="col-md-12">
                <div className="container-payment">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="flexBox-style-space">
                        <p className="modal-footer-text">Name on Card</p>
                        <p className="modal-footer-subtext">John Doe</p>
                      </div>
                      <div className="flexBox-style-space">
                        <p className="modal-footer-text">Card Number</p>
                        <p className="modal-footer-subtext">
                          4560 5674 3224 4543
                        </p>
                      </div>
                      <div className="flexBox-style-space">
                        <p className="modal-footer-text">Expiry Date</p>
                        <p className="modal-footer-subtext">09/24</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="flexBox-style-space">
                        <p>CVV</p>
                        <div className="modal-foter-input">
                          <input
                            type="text"
                            placeholder="***"
                            value={cvv}
                            onChange={(e) => {
                              setCvv(e.target.value);
                            }}
                          />
                        </div>
                        <img
                          src={IconMasterCard}
                          className="image-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="mt-4 mb-4 text-center">
                      <StripeCheckout
                        amount="10.00"
                        name="STRIPE_INTEGRATION"
                        label=" Submit & Pay"
                        // description={`Order of ${computeQuantity(cart)} items!`}
                        // image='LINKTOIMAGE'
                        stripeKey="pk_test_51JrnF3KzMNWu8IhY59SUNnExbLog1Oz5CMpnkmWk8GaROLwXsW2OWyZQB4EH61fe5BUvQNjG3AnsNaUgJtE2ps6V00IUI06OvF"
                        currency="INR"
                        email={userData?.email}
                        token={onToken}
                      >
                        <button
                          className="btn btn-primary submit-pay-Btn"
                          onClick={() => {
                            AssignJob();
                          }}
                        >
                          Submit & Pay
                        </button>
                      </StripeCheckout>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Elements stripe={stripePromise}>
                        <StripeForm />
                    </Elements> */}
        </div>
      </Modal>
    </>
  );
}

export default BookingModal;
