import React,{useState,useEffect} from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import JobRequestCard from '../../components/JobBanner/JobRequestCard';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllJOBS } from '../../network/Network';
import { GetJobs } from '../../redux/actions/AuthAction';

function JobRequest(props) {
    const history = useHistory();
    const dispatch = useDispatch()
    const Jobs = useSelector(state => state.AuthReducer.jobs);
    const token = useSelector(state => state.AuthReducer.token);
    console.log(Jobs)
    const [jobData,setJobData] = useState([])
    
    // == get Jobs API ==
    useEffect(() => {
        GetAllJOBS(token)
        .then((res) => {
            setTimeout(() => { 
                console.log(res)
                setJobData(res?.data?.data)
                dispatch(GetJobs(res?.data?.data))
            }, 800);            
        })
        .catch((err) => {
            console.log(err)  
        })
    }, [])

    const data=[
        {
            id:1,
            name: 'Martha Karolin',
            certificate: 'Certified Driver',
            detail: 'Aug 20, 9.30 AM - 30 Mins - $20 - PENDING',
            button: 'View Details',
            rating: '4.0',
            status: 'Waiting for Approval',
        },
    ]
    const[activeBooking,setActiveBooking] = useState(false)
    const approveBooking = () => {
        setActiveBooking(!activeBooking)
    }
    return (
        <>
            <div className="Home-main-Container service activeBooking">
                <div className="container">
                    <h2 className="dashboard-content-Title">Job Requests</h2>
                    <p className="dashboard-content-SubTitle" style={{ marginBottom: '76px'}}>Home - Job Requests</p>
                    <div 
                        className="back-container"
                        onClick={()=>{history.goBack()}}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="back-icon"/>
                    </div>

                    <div className="contact-container">
                        <div className="profile-card-container">
                            {jobData?.map((item,index)=>{
                                return(         
                                    <JobRequestCard  
                                        item={item} 
                                        page={"jobRequest"}
                                        activeBooking={activeBooking}
                                        approveBooking={approveBooking}
                                    />
                                )
                            })}
                         
                        </div>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default JobRequest;
