import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ImageUrl } from "../../network/ApiUrl";

function AllServicesDetail(props) {
  const {
    item,
    index,
    AddUserServices,
    setAddService,
    addService,
    setServiceId,
    serviceId,
    DeleteUserServices,
  } = props;
  const history = useHistory();
  const Role = useSelector((state) => state.AuthReducer.users.role);
  return (
    <>
      <div className="row" style={{ marginBottom: "36px" }} key={item?.id}>
        <div className="col-md-2 col-sm-2 col-12">
          <div
            className="service-card"
            style={{ border: "1px solid #5c3da433" }}
          >
            <div className="card-icon">
              <img
                src={`${ImageUrl}${item?.icon}`}
                className="image-fluid image-width"
              />
            </div>
            <p className="service-title">{item?.name}</p>
          </div>
        </div>
        <div className="col-md-7 col-sm-7 col-12">
          <p className="service-description mt-3">{item?.description}</p>
        </div>
        <div className="col-md-3 col-sm-3 col-12">
          <div className="mt-5 booknow-Btn-mobileView">
            {Role == "Customer" ? (
              <button
                type="button"
                className="btn btn-primary bookNow-Btn"
                onClick={() => {
                  history.push({
                    pathname: "/driveBooking",
                    state: item?.id,
                  });
                }}
              >
                Book Now
              </button>
            ) : serviceId !== item.id ? (
              <button
                type="button"
                className="btn btn-primary added-Btn"
                onClick={() => {
                  AddUserServices(item?.id);
                  setServiceId(item.id);
                }}
              >
                Added
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary added-Btn"
                onClick={() => {
                  DeleteUserServices(item?.id, index);
                  setAddService(!addService);
                }}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllServicesDetail;
