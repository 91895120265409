import React,{useState} from 'react';
import { Link, useHistory } from "react-router-dom"; 
import profile from "../../assets/image/profile.png";
import { useSelector, useDispatch } from 'react-redux';
import {logout} from '../../redux/actions/AuthAction';
import { ImageUrl } from '../../network/ApiUrl';
import {ramdomImage} from "../../constant/constFunctions";

function Sidebar() {
    let history = useHistory();
    const dispatch = useDispatch()
    const [home, setHome] = useState("true")
    const [myProfile, setMyProfile] = useState("false")
    const [booking, setBooking] = useState("false")
    const [payment, setPayment] = useState("false")
    const [chat, setChat] = useState("false")
    const [service, setService] = useState("false")
    const [contact, setContact] = useState("false")
    const [activeBooking, setActiveBooking] = useState("false")
    const [prevBooking, setPrevBooking] = useState("false")
    const [type, setType] = useState("home")
    const [loading, setLoading] = useState(false)
    const activeStateHandler = (type) => {
        if(type === "home"){
            setHome(true)
            setMyProfile(false)
            setBooking(false)
            setPayment(false)
            setChat(false)
            setService(false)
            setContact(false)
            setPrevBooking(false)
            setActiveBooking(false)
        }
            else if(type === "profile"){
                setHome(false)
                setMyProfile(true)
                setBooking(false)
                setPayment(false)
                setChat(false)
                setService(false)
                setContact(false)
                setPrevBooking(false)
                setActiveBooking(false)
            }
            else if(type === "booking"){
                setHome(false)
                setMyProfile(false)
                setBooking(true)
                setPayment(false)
                setChat(false)
                setService(false)
                setContact(false)
                setPrevBooking(false)
                setActiveBooking(false)
            }
            else if(type === "payment"){
                setHome(false)
                setMyProfile(false)
                setBooking(false)
                setPayment(true)
                setChat(false)
                setService(false)
                setContact(false)
                setPrevBooking(false)
                setActiveBooking(false)
            }
            else if(type === "chat"){
                setHome(false)
                setMyProfile(false)
                setBooking(false)
                setPayment(false)
                setChat(true)
                setService(false)
                setContact(false)
                setPrevBooking(false)
                setActiveBooking(false)
            }
            else if(type === "service"){
                setHome(false)
                setMyProfile(false)
                setBooking(false)
                setPayment(false)
                setChat(false)
                setService(true)
                setContact(false)
                setPrevBooking(false)
                setActiveBooking(false)
            }
            else if(type === "service"){
                setHome(false)
                setMyProfile(false)
                setBooking(false)
                setPayment(false)
                setChat(false)
                setService(true)
                setContact(false)
                setPrevBooking(false)
                setActiveBooking(false)
            }
            else if(type === "prevBooking"){
                setHome(false)
                setMyProfile(false)
                setBooking(false)
                setPayment(false)
                setChat(false)
                setService(true)
                setContact(false)
                setPrevBooking(true)
                setActiveBooking(false)
            }
            else if(type === "activeBooking"){
                setHome(false)
                setMyProfile(false)
                setBooking(false)
                setPayment(false)
                setChat(false)
                setService(true)
                setContact(false)
                setPrevBooking(false)
                setActiveBooking(true)
            }
        else{
            setHome(false)
            setMyProfile(false)
            setBooking(false)
            setPayment(false)
            setChat(false)
            setService(false)
            setContact(true)
        }
    }
    const UserData = useSelector(state => state.AuthReducer.users)

    const LogoutHandler = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)   
            dispatch(logout())          
          }, 200);
       
        history.push('/login')   
      }

    const [accordianState, setAccordianState] = useState(false)
    return (
       <>   
            <div className="profileBox">
                <div className="profile-Container">
                    <div className="profile-img">
                        {
                            UserData &&
                            (
                                UserData.avatar?
                                    <img src={`${ImageUrl}${UserData?.avatar}`} className="image-fluid image-width" />
                                    :
                                    <img src={`${ramdomImage(UserData?.first_name)}`} className="image-fluid image-width" />
                            )
                        }
                        <img src={`${ImageUrl}${UserData?.avatar}`} className="image-fluid image-width" /> 
                        {/* <img src ={profile} className="image-fluid" /> */}
                    </div>
                </div>
                    <h4 className="user-name">{UserData?.first_name}</h4>
                    <p className="user-email">{UserData?.email}</p>
            </div>
            <ul className="sidebar-nav">
                <li className={type === "home" ? "active" : null}>
                    <Link to="/"
                        onClick={()=>activeStateHandler(setType("home"))}
                >
                        <i className="fa fa-home"></i>Home
                    </Link>
                </li>
                <li className={type === "profile" ? "active" : null}>
                    <Link to="/profile"
                        onClick={()=>activeStateHandler(setType("profile"))}
                    >   
                        <i className="fa fa-user"></i>My Profile
                    </Link>
                </li>

                <li className={`${accordianState ? "active accordion" : "accordion"}`} id="accordionExample" 
                    onClick={()=>{
                        setAccordianState(!accordianState);
                        // activeStateHandler(setType("booking"))
                }}>
                    <div className="card" style={{backgroundColor: 'transparent'}}>
                        <div className="card-header" id="headingOne" style={{backgroundColor: 'transparent'}}>
                            <button className="btn btn-link btn-block text-left" 
                                type="button" data-toggle="collapse" data-target="#collapseOne" 
                                aria-expanded="true" aria-controls="collapseOne"
                            >
                                <i className="fa fa-star accordian-icon" 
                                    // style={{  marginLeft: '-16px'}}
                                >
                                </i>Bookings {accordianState ? <span style={{ marginLeft: '35px'}}>+</span>: <span style={{ marginLeft: '35px'}}>-</span>}
                            </button>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body">
                                <Link to="/activebooking" 
                                    className={`${type === "activeBooking" ? "text-underline dropdown-item accordian-items" :"dropdown-item accordian-items"}`} 
                                    style={{ marginTop: '-22px'}}
                                    onClick={()=>activeStateHandler(setType("activeBooking"))}
                                >
                                    Active Bookings
                                </Link>
                                <Link to="/previousbooking" 
                                    className={`${type === "prevBooking" ? "text-underline dropdown-item accordian-items" :"dropdown-item accordian-items"}`} 
                                    style={{    marginBottom: '9px'}}
                                    onClick={()=>activeStateHandler(setType("prevBooking"))}
                                >
                                    Prevous Bookings
                                </Link>
                            </div>
                        </div>
                    </div>
                </li>
  
                <li className={type === "payment" ? "active" : null}>
                    <Link to="/Payment"
                            onClick={()=>activeStateHandler(setType("payment"))}
                    >
                        <i className="fa fa-dollar-sign"></i>Payments
                    </Link>
                </li>
                <li className={type === "chat" ? "active" : null}>
                    <Link to="/chatview"
                        onClick={()=>activeStateHandler(setType("chat"))}
                    >
                        <i className="fa fa-comment-alt"></i>Chat
                    </Link>
                </li>
                <li className={type === "service" ? "active" : null}>
                    <Link to="/service"
                        onClick={()=>activeStateHandler(setType("service"))}
                    >
                        <i className="fa fa-briefcase"></i>
                        Services
                    </Link>
                </li>
                <li className={type === "contact" ? "active" : null}>
                    <Link to="/contactus"
                        onClick={()=>activeStateHandler(setType("contact"))}
                    >
                        <i className="fa fa-envelope"></i>Contact Us
                    </Link>
                </li>
                <li className="logout-Container">
                    <div>
                        <p className="version-text">Version 2.0</p>
                        <div style={{textAlign: 'center'}}>
                            <button type="button" 
                                className="btn btn-danger Logout-Btn"
                                onClick={()=>{LogoutHandler()}}
                                disabled={loading}
                            >
                                {loading ? "loading.." : "Logout"}
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
       </>
    )
}

export default Sidebar;
