import React from 'react'
import ProtectedRoute from "./ProtectedRoutes";
import ContactUs from '../Views/ContactUs';
import Profile from "../Views/Profile/Profile";
import EditProfile from "../Views/Profile/EditProfile";
import Notification from "../Views/Notification/Notification";
import Home from "../Views/Home";
import Service from '../Views/Service';
import ActiveBooking from '../Views/ActiveBooking/ActiveBooking';
import DriveBooking from '../Views/DriveBooking/DriveBooking';
import ProviderProfile from '../Views/ProviderProfile/ProviderProfile';
import NewPaymentMethod from '../Views/EditPayment/NewPaymentMethod';
import PreviousBooking from '../Views/ActiveBooking/PreviousBooking';
import Payment from '../Views/EditPayment/Payment';
import RequestConfirmation from '../Views/UserRequestConfirmation/RequestConfirmation';
import ActiveBookingDetails from '../Views/ActiveBooking/ActiveBookingDetails';
import ViewOnMap from '../Views/ActiveBooking/ViewOnMap';
import BookingFinished from '../Views/ActiveBooking/BookingFinished';
import ChatView from '../Views/ChatView/ChatView';
import JobRequest from '../Views/ProviderProfile/JobRequest';
import JobRequestDetails from '../Views/ProviderProfile/JobRequestDetails';
import DeliveryConfirmation from '../Views/ProviderProfile/DeliveryConfirmation';
import ProviderActiveBookingDetails from '../Views/ProviderProfile/ProviderActiveBookingDetails';
import RideFinished from '../Views/ProviderProfile/RideFinished';
import { Redirect } from 'react-router';

function Routes() {
    return (
        <>
                <ProtectedRoute  exact path='/' component={Home} />
                <ProtectedRoute  path="/profile" component={Profile} />
                <ProtectedRoute  path='/editprofile' component={EditProfile} />
                <ProtectedRoute  path='/activebooking' component={ActiveBooking} />
                <ProtectedRoute  path='/previousbooking' component={PreviousBooking} />
                <ProtectedRoute  path='/contactus' component={ContactUs} />
                <ProtectedRoute  path='/notification' component={Notification} />
                <ProtectedRoute  path='/drivebooking' component={DriveBooking} />
                <ProtectedRoute  path='/providerprofile' component={ProviderProfile} />
                <ProtectedRoute  path="/service" component={Service} />
                <ProtectedRoute  path="/newpaymentmethod" component={NewPaymentMethod} />
                <ProtectedRoute  path="/payment" component={Payment} />
                <ProtectedRoute  path="/requestconfirmation" component={RequestConfirmation} />
                <ProtectedRoute  path="/activebookingdetails" component={ActiveBookingDetails} />
                <ProtectedRoute  path="/viewonmap" component={ViewOnMap} />
                <ProtectedRoute  path="/bookingfinished" component={BookingFinished} />
                <ProtectedRoute  path="/chatview/:username?" component={ChatView} />
                <ProtectedRoute  path="/jobrequest" component={JobRequest} />
                <ProtectedRoute  path="/jobrequestdetails" component={JobRequestDetails} />
                <ProtectedRoute  path="/deliveryconfirmation" component={DeliveryConfirmation} />
                <ProtectedRoute  path="/provideractivebookingdetails" component={ProviderActiveBookingDetails} />
                <ProtectedRoute  path="/ridefinished" component={RideFinished} />
                <Redirect to="/" />
        </>
    )
}

export default Routes;