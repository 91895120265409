import React, { useState } from "react";
import {
  faCheckCircle,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { profile2, profile3 } from "../../constant";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ImageUrl } from "../../network/ApiUrl";
import moment from "moment";
import { GetJobs } from "../../redux/actions/AuthAction";
import { UpdateJOB } from "../../network/Network";

function ProviderActiveBookingDetails(props) {
  const { JobId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.AuthReducer?.token);
  const Jobs = useSelector((state) => state.AuthReducer?.jobs);
  const Role = useSelector((state) => state.AuthReducer?.users.role);
  console.log({ Jobs });
  const [JobData, setJobData] = useState(Jobs);
  console.log(JobData);
  const selectedjobDetails = JobData?.find((x) => x.id === JobId);
  console.log(selectedjobDetails);
  const [loading, setLoading] = useState(false);

  // == Update Job API ==
  const BookingFinished = () => {
    setLoading(true);
    const data = {
      status: "Completed",
    };
    console.log(data);
    let id = selectedjobDetails?.provider?.id;
    console.log(id);
    UpdateJOB(id, data, token)
      .then((res) => {
        console.log(res);
        setLoading(false);
        dispatch(GetJobs(res?.data?.data));
        history.push("/ridefinished");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  console.log(selectedjobDetails);
  return (
    <>
      <div className="Home-main-Container service bookingDetails jobDetails">
        <div className="container">
          <h2 className="dashboard-content-Title">View Details</h2>
          <p
            className="dashboard-content-SubTitle"
            style={{ marginBottom: "76px" }}
          >
            Home - Job Requests - Details
          </p>

          <div
            className="back-container"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </div>

          <div className="contact-container">
            <div className="profile-card-container bookingCard-padding">
              <div className="flexBox-style">
                <div className="profile-container">
                  <img
                    src={`${ImageUrl}${selectedjobDetails?.provider?.avatar}`}
                    className="image-fluid image-width"
                  />
                </div>
                <FontAwesomeIcon icon={faArrowRight} className="right-icon" />
                <div className="profile-container">
                  <img
                    src={`${ImageUrl}${selectedjobDetails?.customer?.avatar}`}
                    className="image-fluid image-width"
                  />
                  {/* <img src={profile2} className="image-fluid image-width" alt=""/> */}
                </div>
              </div>
              <h3 className="user-name" style={{ marginTop: "12px" }}>
                {selectedjobDetails?.customer?.full_name}
              </h3>
              <div className="flexBox-style" style={{ marginTop: "-11px" }}>
                <div className="flexBox-style">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="certified-badge"
                  />
                  <p className="certificate-text" style={{ marginLeft: "9px" }}>
                    Certified User
                  </p>
                </div>
              </div>

              <p className="paid-service">
                ${selectedjobDetails?.price} Paid for the service -{" "}
                <span style={{ fontFamily: "Poppins-SemiBold" }}>
                  Payable after Service
                </span>
              </p>

              <div className="row">
                <div className="col-md-5">
                  <h3 className="status-text" style={{ marginTop: "30px" }}>
                    Summary
                  </h3>
                  <div className="about-container">
                    <div className="flexBox-style-space summary-container">
                      <p className="user-card-title">Date</p>
                      <p className="user-card-data">
                        {moment(selectedjobDetails?.created_at).format(
                          "MMM Do YY, h:mm a"
                        )}
                      </p>
                    </div>
                    <div className="flexBox-style-space summary-container">
                      <p className="user-card-title">Duration</p>
                      <p className="user-card-data">
                        {selectedjobDetails?.duration}
                      </p>
                    </div>
                    <div className="flexBox-style-space summary-container">
                      <p className="user-card-title">Price</p>
                      <p className="user-card-data">
                        ${selectedjobDetails?.price}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <h3 className="status-text" style={{ marginTop: "30px" }}>
                    Pick & Drop Details
                  </h3>
                  <div className="user-card-content">
                    <div className="user-card-InnerContainer">
                      <p className="user-card-title">Pick Address</p>
                      <p className="user-card-para">
                        {selectedjobDetails?.destination_lat}
                      </p>
                      <p
                        className="user-card-title"
                        style={{ marginTop: "38px" }}
                      >
                        Drop Address
                      </p>
                      <p className="user-card-para">
                        {selectedjobDetails?.destination_lng}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 col-sm-4 col-12">
                  <div
                    className="viewMap-btn-mobileView"
                    style={{ marginTop: "32px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary  assignJob-Btn"
                      onClick={() => {
                        history.push("/ViewOnMap");
                      }}
                    >
                      View Map
                    </button>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-12">
                  {selectedjobDetails?.status === "Accepted" ? (
                    <>
                      <div style={{ textAlign: "center", marginTop: "32px" }}>
                        <button
                          type="button"
                          className="btn btn-success accept-btn"
                          onClick={() => {
                            BookingFinished();
                          }}
                          disabled={loading}
                        >
                          {loading ? "loading..." : "Finish Job"}
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="col-md-4 col-sm-4 col-12">
                  <div
                    className="viewMap-btn-mobileView"
                    style={{ marginTop: "32px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-info chat-Btn"
                      onClick={() => {
                        history.push({
                          pathname: `/chatview/${
                            Role !== "Customer"
                              ? selectedjobDetails?.customer?.id
                              : selectedjobDetails?.provider?.id
                          }`,
                          state: selectedjobDetails,
                        });
                      }}
                    >
                      Chat
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProviderActiveBookingDetails;
