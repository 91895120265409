import React, { useState } from "react";
import { user3 } from "../../constant";
import { useHistory } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { faArrowLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ImageUrl } from "../../network/ApiUrl";
import { useSelector, useDispatch } from "react-redux";

function ActiveBookingCard(props) {
  let history = useHistory();
  const {
    item,
    page,
    activeBooking,
    approveBooking,
    setSelectedJobId,
    selectedJobId,
  } = props;
  const Role = useSelector((state) => state.AuthReducer.users.role);
  const [rating, setRating] = useState(0);
  const handleRating = (rate) => {
    setRating(rate);
  };
  return (
    <>
      <div
        className={`${
          page == "previousBooking"
            ? "Rejected-bg"
            : item?.status === "Accepted"
            ? "activeBooking-bg"
            : "pendingBooking-bg"
        }`}
      >
        <div className="row">
          <div className="col-md-5 col-sm-5 col-12">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "74px",
              }}
            >
              <div className="image-container">
                <img
                  src={`${ImageUrl}${
                    Role === "Customer"
                      ? item?.provider?.avatar
                      : item?.customer?.avatar
                  }`}
                  className="image-fluid image-width"
                  alt=""
                />
              </div>
              <div
                style={{ marginLeft: "12px", marginTop: "16px" }}
                key={item?.id}
              >
                <p className="user-Name">
                  {Role === "Customer"
                    ? item?.provider?.full_name
                    : item?.customer?.full_name}
                </p>
                <div className="flexBox-align">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="booking-certified-badge"
                  />
                  <p className="certified-text">
                    {Role === "Customer"
                      ? "Certified Driver"
                      : "Certified User"}
                  </p>
                </div>
              </div>
              <div className="rating-container">
                {page == "previousBooking" ? (
                  <Rating
                    onClick={handleRating}
                    ratingValue={rating} /* Rating Props */
                  />
                ) : (
                  <>
                    <p>{item?.rating}</p>
                    <span className="fa fa-star checked star-icon"> </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3 col-12">
            <div className="activeBookingDate-container">
              <p className="detail-text">
                {moment(item?.updated_at).format("MMMM Do YYYY, h:mm a")}-$
                {item.price}
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 col-12">
            <div className="booking-btn-container">
              <div className="activeBooking-footer">
                {page == "previousBooking" ? (
                  Role == "Customer" ? (
                    <>
                      <p
                        onClick={() => {
                          history.push({
                            pathname: "/activebookingdetails",
                            state: {
                              jobId: item?.id,
                              page: "previousBooking",
                            },
                          });
                        }}
                        className="status-text2"
                      >
                        View Details
                      </p>
                      <button
                        type="button"
                        className={`${
                          page == "previousBooking"
                            ? "btn btn-primary Rejected-btn"
                            : item?.status === "Accepted"
                            ? "btn btn-primary viewDetail"
                            : "btn btn-primary notification-Btn"
                        }`}

                        // onClick={(e)=>{approveBooking();
                        //     setSelectedJobId(item?.id)}}
                      >
                        {item?.status === "Pending"
                          ? "Waiting for Approval"
                          : item?.status === "Accepted"
                          ? "Accepted"
                          : "Rejected"}
                      </button>
                    </>
                  ) : (
                    <>
                      <p
                        onClick={() => {
                          history.push({
                            pathname: "/activebookingdetails",
                            state: {
                              jobId: item?.id,
                              page: "previousBooking",
                            },
                          });
                        }}
                        className="status-text2"
                      >
                        View Details
                      </p>
                      <button
                        type="button"
                        className={`${
                          page == "previousBooking"
                            ? "btn btn-primary Rejected-btn"
                            : item?.status === "Accepted"
                            ? "btn btn-primary viewDetail"
                            : "btn btn-primary notification-Btn"
                        }`}

                        // onClick={(e)=>{approveBooking();
                        //     setSelectedJobId(item?.id)}}
                      >
                        {item?.status === "Pending"
                          ? "Waiting for Approval"
                          : item?.status === "Accepted"
                          ? "Accepted"
                          : "Rejected"}
                      </button>
                    </>
                  )
                ) : (
                  <>
                    {Role === "Customer" ? (
                      <>
                        <p
                          // onClick={(e)=>{approveBooking();
                          //             setSelectedJobId(item?.id)}}
                          className="status-text"
                        >
                          {item?.status == "Pending"
                            ? "Waiting for Approval"
                            : item?.status == "Approved"
                            ? "Approved"
                            : "Rejected"}
                        </p>
                        <button
                          type="button"
                          className={`${
                            page == "previousBooking"
                              ? "btn btn-primary Rejected-btn"
                              : item?.status === "Accepted"
                              ? "btn btn-primary viewDetail"
                              : "btn btn-primary notification-Btn"
                          }`}
                          onClick={() => {
                            history.push({
                              pathname: "/activebookingdetails",
                              state: {
                                jobId: item?.id,
                              },
                            });
                          }}
                        >
                          View Details
                        </button>
                      </>
                    ) : (
                      <>
                        <p
                          onClick={() => {
                            history.push({
                              pathname: "/activebookingdetails",
                              state: {
                                jobId: item?.id,
                              },
                            });
                          }}
                          className="status-text"
                        >
                          View Details
                        </p>
                        <button
                          type="button"
                          className={`${
                            page == "previousBooking"
                              ? "btn btn-primary Rejected-btn"
                              : item?.status === "Accepted"
                              ? "btn btn-primary viewDetail"
                              : "btn btn-primary notification-Btn"
                          }`}
                          onClick={(e) => {
                            approveBooking();
                            setSelectedJobId(item?.id);
                          }}
                        >
                          {item?.status === "Pending"
                            ? "Waiting for Approval"
                            : item?.status === "Accepted"
                            ? "Accepted"
                            : "Rejected"}
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ActiveBookingCard;
