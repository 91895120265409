import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from 'react-redux'

function ProtectedRoutes({ component: Component, ...restOfProps }) {
  const isLogin = useSelector(state => state.AuthReducer.isLogin);
    // const isLogin = true;
  return (
    <Route
      {...restOfProps}
      render={(props) => 
        isLogin ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}


export default ProtectedRoutes;