import React, { useState } from "react";
import { Link } from "react-router-dom";
import { faCheckCircle, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserCard from "../../components/UserCard/UserCard";
import { useHistory } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import MuiStepper from "../../components/Stepper/MuiStepper";
import { profile3, user1, user2 } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ImageUrl } from "../../network/ApiUrl";

function UserActiveBookingDetails(props) {
  const { JobId, page } = props;
  const history = useHistory();
  console.log(JobId);
  const Jobs = useSelector((state) => state.AuthReducer?.jobs);
  const Role = useSelector((state) => state.AuthReducer?.role);
  console.log(Jobs);
  const [JobData, setJobData] = useState(Jobs);
  console.log(JobData);
  const selectedjobDetails = JobData?.find((x) => x.id === JobId);
  console.log(selectedjobDetails);
  const userDetail = [
    {
      id: 1,
      title: "Useful Driver ! ",
      name: "Tommy",
      rating: "4.0",
      image: user1,
    },
    {
      id: 2,
      title: "Worthy",
      name: "Amy",
      rating: "4.0",
      image: user2,
    },
    {
      id: 3,
      title: "Useful Driver ! ",
      name: "Tommy",
      rating: "4.0",
      image: user1,
    },
    {
      id: 4,
      title: "Worthy",
      name: "Amy",
      rating: "4.0",
      image: user2,
    },
  ];
  const [loginMode, setLoginMode] = useState("provider");

  return (
    <>
      <div className="Home-main-Container service bookingDetails">
        <div className="container">
          <h2 className="dashboard-content-Title">Booking Details</h2>
          <p
            className="dashboard-content-SubTitle"
            style={{ marginBottom: "29px" }}
          >
            Bookings - Active Bookings - Details
          </p>

          <div
            className="back-container"
            onClick={() => {
              history.push("/drivebooking");
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <StyledEngineProvider injectFirst>
              <MuiStepper />
            </StyledEngineProvider>
          </div>

          <div className="contact-container">
            <div className="profile-card-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="profile-pic">
                  <img
                    src={`${ImageUrl}${selectedjobDetails?.provider?.avatar}`}
                    className="image-fluid image-width"
                  />
                </div>
              </div>
              <h3 className="user-name">
                {selectedjobDetails?.provider?.full_name}
              </h3>
              <div className="online"></div>
              <div className="flexBox-style" style={{ marginTop: "-11px" }}>
                <div className="flexBox-style">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="certified-badge"
                  />
                  <p className="certificate-text">Certified Driver</p>
                </div>
                <div className="flexBox-style">
                  <p className="rating">{selectedjobDetails?.rating}4.0</p>
                  <span className="fa fa-star star-icon"></span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-5">
                  <h3 className="status-text" style={{ marginTop: "30px" }}>
                    Summary
                  </h3>
                  <div className="about-container">
                    <div className="flexBox-style-space summary-container">
                      <p className="user-card-title">Date</p>
                      <p className="user-card-data">
                        {moment(selectedjobDetails?.updated_at).format(
                          "MMM Do YY, h:mm a"
                        )}
                      </p>
                    </div>
                    <div className="flexBox-style-space summary-container">
                      <p className="user-card-title">Duration</p>
                      <p className="user-card-data">
                        {selectedjobDetails?.duration}
                      </p>
                    </div>
                    <div className="flexBox-style-space summary-container">
                      <p className="user-card-title">Price</p>
                      <p className="user-card-data">
                        ${selectedjobDetails?.price}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <h3 className="status-text" style={{ marginTop: "30px" }}>
                    Pick & Drop Details
                  </h3>
                  <div className="user-card-content">
                    <div className="user-card-InnerContainer">
                      <p className="user-card-title">Pick Address</p>
                      <p className="user-card-para">
                        {selectedjobDetails?.address}
                      </p>
                      <p
                        className="user-card-title"
                        style={{ marginTop: "38px" }}
                      >
                        Drop Address
                      </p>
                      <p className="user-card-para">
                        {selectedjobDetails?.destination_address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flexBox-style">
                <div style={{ textAlign: "center", marginTop: "32px" }}>
                  <button
                    type="button"
                    className="btn btn-primary assignJob-Btn"
                    onClick={() => {
                      history.push({
                        pathname: "/viewonmap",
                        state: selectedjobDetails,
                      });
                    }}
                  >
                    View on Map
                  </button>
                </div>
                <div style={{ marginTop: "32px", float: "right" }}>
                  <button
                    type="button"
                    className="btn btn-info chat-Btn"
                    onClick={() => {
                      history.push({
                        pathname: `/chatview/${
                          Role === "Customer"
                            ? selectedjobDetails?.customer?.id
                            : selectedjobDetails?.provider?.id
                        }`,
                        state: {
                          data:
                            Role === "Customer"
                              ? selectedjobDetails?.customer
                              : selectedjobDetails?.provider,
                          page: "ActiveBookingDetails",
                        },
                      });
                    }}
                  >
                    Chat
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserActiveBookingDetails;
