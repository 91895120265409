import React from 'react';
import { Formik, Form } from 'formik';
import { TextField } from '../../components/TextField/TextField';
import * as Yup from 'yup';
import logo from "../../assets/image/logo.png";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {forgotPasswordEmail} from '../../redux/actions/AuthAction';

export const ForgotPassword = () => {
    const dispatch = useDispatch()
    let history = useHistory();
  const validate = Yup.object({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
  })
  return (
      <>
        <div className="Login forgotPassword">
            <div className="container">
                <div className="main-Container">
                    <div className="row">
                        <div className="logo-container logo">
                              <img src={logo} className="image-fluid" />
                        </div>
                        <div 
                            className="back-container mt-4"
                            onClick={()=>{history.goBack()}}
                            >
                            <FontAwesomeIcon icon={faArrowLeft} className="back-icon"/>
                        </div>
                        <div className="col-md-6">
                            <h2 className="login-title">Forgot Password</h2>
                        </div>
                    </div>
                </div>
               
                <div className="row">
                <Formik
                    initialValues={{
                        email: '',
                    }}
                    validationSchema={validate}                 
                    onSubmit={values => {
                        console.log(values)
                        if(!values.email ){
                            toast.error("please enter correct email")
                        }
                        else{
                            let data = {email: values.email,}
                            dispatch(forgotPasswordEmail(data))
                            history.push('/newpassword')
                        }
                    }}
                >
                {formik => (
                    <div>
                    <Form>
                            <div className="col-md-12">
                                <TextField label="Email" name="email" placeholder="Enter your email" type="email" />
                            </div>
                             <div className="col-md-12 forgotPass-container">
                                <button 
                                    className="btn btn-primary Login-Btn" 
                                    type="submit"
                                    // onClick={()=>{ForgotPasswordHandler}}
                                >
                                    Next
                                </button>
                            </div>
                    </Form>
                    </div>
                    )}
                </Formik>
                   
               </div>
            </div>
        </div>
    </>
  )
}

