import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_ERROR,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    SET_PASSWORD_REQUEST,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_ERROR,
    // GET_ME_SUCCESS,
    // SET_PROFILE_IMG,
    // LOGOUT_REQUEST,
    // LOGOUT_SUCCESS,
    // LOGOUT_ERROR,
    LOGOUT,
    USER_TOKEN,
    JOBS,
    EDIT_PROFILE,
    All_PAYMENT_METHOD,
    NOTIFICATION,
    CHAT_SEND,
    SERVICES,
    PROVIDERSERVICEDATA,
    USER_MESSAGES,
    CHAT_USERDATA, ALERTS, SET_ALERTS,
} from "../Types";

const initialState = {
    alert: null,
};

const Alerts = (state = initialState, action) => {
    switch (action.type) {

        case ALERTS:
            return {
                ...state,
                alert: action.payload,
            }

        case SET_ALERTS:
            return {
                ...state,
                alert: action.payload,
            }


        default:
            return state;
    }
};
export default Alerts;
  


