import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ImageUrl } from "../../network/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

function AllMessagesContainer(props) {
  const {
    userData,
    UserDataRedux,
    postMessage,
    setPostMessage,
    profileMessagesData,
    selectedChatData,
    customerData,
    providerData,
  } = props;
  const Role = useSelector((state) => state.AuthReducer.users.role);
  console.log(postMessage);
  const UserMessages = useSelector((state) => state.AuthReducer.UserMessages);
  console.log(UserMessages);
  console.log("msg", profileMessagesData);
  console.log(selectedChatData);
  return (
    <>
      <div className="All-messages-container">
        {profileMessagesData?.reverse().map((item, index) => {
          console.log(item);
          return (
            <>
              <div
                className={`${
                  item?.from_id == UserDataRedux?.id ? "outgoing" : "incoming"
                }`}
              >
                <div
                  className={`${
                    item?.from_id == UserDataRedux?.id
                      ? "chat-picture-outgoing"
                      : "chat-picture-incoming"
                  }`}
                >
                  <img
                    src={`${ImageUrl}${
                      item?.from_id == UserDataRedux?.id
                        ? UserDataRedux?.avatar
                        : selectedChatData
                        ? selectedChatData?.avatar
                        : Role === "Provider"
                        ? customerData?.avatar
                        : providerData?.avatar
                    }`}
                    className="image-fluid image-width"
                  />
                </div>
                {item?.message_type === "photo" ? (
                  <>
                    <div
                      className={`${
                        item?.from_id == UserDataRedux?.id
                          ? "chat-message-outgoing"
                          : "chat-message-incoming"
                      }`}
                    >
                      <p>
                        <img
                          src={`${ImageUrl}${item?.message}`}
                          className="image-fluid image-width"
                        />{" "}
                      </p>
                    </div>
                  </>
                ) : item?.message_type === "attachment" ? (
                  <div
                    className={`${
                      item?.from_id == UserDataRedux?.id
                        ? "chat-message-outgoing"
                        : "chat-message-incoming"
                    }`}
                  >
                    <p>
                      <a href="#" download={item?.message}>
                        <FontAwesomeIcon
                          icon={faFileDownload}
                          className="downloadChat-Icon"
                        />
                      </a>
                      Download
                    </p>
                    <p style={{ fontSize: "10px" }}>{item?.message}</p>
                  </div>
                ) : (
                  <>
                    <div
                      className={`${
                        item?.from_id == UserDataRedux?.id
                          ? "chat-message-outgoing"
                          : "chat-message-incoming"
                      }`}
                    >
                      <p>{item?.message}</p>
                    </div>
                  </>
                )}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default AllMessagesContainer;
