import React, { useState } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { profile2, noPayment, doodle, card } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import { ImageUrl } from "../../network/ApiUrl";
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import { AddPaymentMethods } from "../../network/Network";

function Payment(props) {
  let history = useHistory();
  // const [paymentCard, setPaymentCard] = useState(false)
  const UserData = useSelector((state) => state.AuthReducer.users);
  const Token = useSelector((state) => state.AuthReducer.token);
  const PaymentMethod = useSelector((state) => state.AuthReducer.paymentMethod);

  const [paymentData, setPaymentData] = useState([]);
  const [token_visa, setToken_visa] = useState("");

  // stripe Token
  const onToken = (token) => {
    console.log("tok", token);
    setToken_visa(token?.id);
    let data = {
      token: token?.id,
    };
    if (data) {
      AddPaymentMethods(data, Token)
        .then((res) => {
          setPaymentData(res?.data?.data);
          console.log(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("something went wrong");
    }
  };

  console.log(PaymentMethod);
  return (
    <>
      <div className="Home-main-Container service new-Payment">
        <div className="container">
          <h2 className="dashboard-content-Title">Edit Payment</h2>
          <p
            className="dashboard-content-SubTitle"
            style={{ marginBottom: "76px" }}
          >
            Payments
          </p>
          <div
            className="back-container"
            onClick={() => {
              history.push("/");
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </div>
          <div className="contact-container">
            <div className="profile-card-container">
              <div className="flexBox-style">
                <div className="profile-pic">
                  <img
                    src={`${ImageUrl}${UserData?.avatar}`}
                    className="image-fluid image-width"
                  />
                </div>
                <div className="doodle-container">
                  <img src={doodle} className="image-fluid" />
                </div>
              </div>
              <h3 className="user-name mb-3" style={{ marginTop: "18px" }}>
                {UserData?.full_name}
              </h3>
              <div className="payment-card-details">
                {PaymentMethod?.length > 0 ? (
                  <>
                    <div className="user-Card-details">
                      <div className="row">
                        <div className="col-md-8 col-sm-8 col-8">
                          <div className="card-View">
                            <div className="row">
                              {PaymentMethod?.map((item, index) => {
                                return (
                                  <>
                                    <div className="col-md-2 col-sm-2">
                                      <p className="userDetails">
                                        S.NO.{index + 1}
                                      </p>
                                    </div>
                                    <div className="col-md-4 col-sm-4">
                                      <p className="userDetails">
                                        {item?.card_brand}
                                      </p>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                      <p className="userDetails-text">
                                        ************{item?.card_last4}
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-4">
                          <div className="payment-card">
                            <img src={card} className="image-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={noPayment}
                        className="image-fluid nopayment-width"
                        style={{ marginTop: "42px" }}
                      />
                    </div>
                  </>
                )}
              </div>
              {PaymentMethod?.length > 0 ? null : (
                <>
                  <div className="col-md-12 save-container">
                    <StripeCheckout
                      // amount = '10.00'
                      name="STRIPE_INTEGRATION"
                      label="Add New Payment"
                      // description={`Order of ${computeQuantity(cart)} items!`}
                      // image='LINKTOIMAGE'
                      stripeKey="pk_test_51JrnF3KzMNWu8IhY59SUNnExbLog1Oz5CMpnkmWk8GaROLwXsW2OWyZQB4EH61fe5BUvQNjG3AnsNaUgJtE2ps6V00IUI06OvF"
                      // currency="INR"
                      token={onToken}
                    >
                      <button
                        className="btn btn-primary savePayment-Btn"
                        type="submit"
                      >
                        Add New Payment
                      </button>
                    </StripeCheckout>
                    {/* <button 
                                        className="btn btn-primary savePayment-Btn" 
                                        type="submit"
                                        // onClick={()=>{history.push('/newpaymentmethod')}}
                                    >
                                        Add New Payment
                                    </button> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
