export const ApiUrl =
  "http://bidit2.custom-ourbase.com" || "http://localhost:4000";
export const ImageUrl =
  "http://bidit2.custom-ourbase.com/" || "http://localhost:4000";

// export const ApiUrl =
//   "http://192.168.3.46/bidit2-backend/public" || "http://localhost:4000";
// export const ImageUrl =
//   "http://192.168.3.46/bidit2-backend/public/" || "http://localhost:4000";

//export const BasicToken = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InJvbGUiOiJ1c2VyIiwidXNlcklkIjoiNWY1Yjk0YTY2YTNjYTMwMDA0YzJjZWJiIn0sImlhdCI6MTU5OTgzOTMwMiwiZXhwIjoxNjAwMDk4NTAyfQ.Fdft4zwZwOzww6Fdbk2t4UiTz1cpNSrIYr"

// export const DEFAULT_MESSAGE_TIMEOUT = 300;
