import React,{useState,useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function GoogleMapTest(props) {
    const {providerData} = props;
    console.log(providerData)
    const [coords,setCoords] = useState({})
    const [bounds, setBounds] = useState(null);
    console.log(coords)
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
          setCoords({ lat: latitude, lng: longitude });
        });
      }, []);
      console.log(coords)
    return (
        <>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyD2XPrghls3jxtd0d1Vl2jHwVjo8eBuirE' }}
                defaultCenter={coords}
                center={coords}
                defaultZoom={14}
                margin={[50, 50, 50, 50]}
                options={{ disableDefaultUI: true, zoomControl: true,}}
                // onChange={(e) => {
                // setCoords({ lat: e.center.lat, lng: e.center.lng });
                // // setBounds({ ne: e.marginBounds.ne, sw: e.marginBounds.sw });
                //  }}
                // onChildClick={(child) => setChildClicked(child)}
            >
                <div lat={coords?.lat} lng={coords?.lng}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} style={{color:'#dc3545', fontSize: '30px'}}/>
                </div>
                {/* {places.length && places.map((place, i) => (
                <div
                    className={classes.markerContainer}
                    lat={Number(place.latitude)}
                    lng={Number(place.longitude)}
                    key={i}
                >
                    
                    marker
                    <LocationOnOutlinedIcon color="primary" fontSize="large" />
                
                    <Paper elevation={3} className={classes.paper}>
                    <Typography className={classes.typography} variant="subtitle2" gutterBottom> {place.name}</Typography>
                    <img
                        src={place.photo ? place.photo.images.large.url : 'https://www.foodserviceandhospitality.com/wp-content/uploads/2016/09/Restaurant-Placeholder-001.jpg'}
                    />
                    </Paper>
     
                </div>
                ))} */}
            </GoogleMapReact>
        </>
    )
}

export default GoogleMapTest
