import React,{useState,useEffect} from 'react';
import ActiveBookingCard from '../../components/BookingCard/ActiveBookingCard';
import { GetAllJOBS } from '../../network/Network';
import { GetJobs } from '../../redux/actions/AuthAction';
import { useSelector, useDispatch } from 'react-redux';
import { SpinnerCircular } from 'spinners-react';

function PreviousBooking(props) {
    const data=[
        {
            id:1,
            name: 'John Doe',
            certificate: 'Certified Driver',
            detail: 'Aug 20, 9.30 AM - 30 Mins - $20',
            button: 'Rejected',
            rating: '4.0',
            status: 'View Details',
        },
    ]
    const Role = useSelector(state => state.AuthReducer.users.role);
    const token = useSelector(state => state.AuthReducer.token);
    const[activeBooking,setActiveBooking] = useState(false)
    const approveBooking = () => {
        setActiveBooking(!activeBooking)
    }
    const [loading, setLoading] = useState(false)
    const [jobData,setJobData] = useState([])


    // == get Jobs API ==
    useEffect(() => {
        setLoading(true)
        GetAllJOBS(token)
        .then((res) => {
            setTimeout(() => {
                setLoading(false)   
                console.log(res?.data?.data)
                setJobData(res?.data?.data)
            }, 800);            
        })
        .catch((err) => {
            console.log(err)
            setLoading(false)   
        })
    }, [])
    return (
        <>
            <div className="Home-main-Container service activeBooking">
                <div className="container">
                    <h2 className="dashboard-content-Title">Previous Bookings</h2>
                    <p className="dashboard-content-SubTitle" style={{ marginBottom: '76px'}}>Bookings - Previous Bookings</p>
                   
                    <div className="contact-container">
                        <div className="profile-card-container">
                        {loading ? (
                                <div className="loader-container">
                                 <SpinnerCircular 
                                     size="80px"
                                     color="#5C3DA4"
                                 />
                                </div>
                            ) : ( 
                                
                                jobData?.map((item,index)=>{
                                return(
                                    item?.status === "Rejected" ? (
                                        <>
                                            <ActiveBookingCard  
                                                item={item} 
                                                page={"previousBooking"}
                                                // activeBooking={activeBooking}
                                                approveBooking={approveBooking}
                                            />
                                        </>
                                    ) : (null)
                                   
                                )
                            })
                                
                            ) }
                            
                         
                        </div>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default PreviousBooking;
