import React from 'react';

function ProviderProgressBar(props) {
    const {item} = props;
    console.log(item)
    return (
        <>
           <div className="col-md-4" key={item?.id}>
               <p className="progress-bar-status">{item?.status}</p>
               <div className={`${item.id === 1 ? "provider-card-progress1" 
                    : item.id === 2 ? "provider-card-progress2" 
                    : "provider-card-progress3"}`}
                >
                    <div className="image-container">
                        <img src={item?.image} className="image-fluid" alt=""/>
                    </div>
                    <div>
                        <p className="provider-card-num">{item?.num}</p>
                        <div className="flexBox-style-space">
                            <h3 className={`${item?.id === 3 ? "provider-card-title3" : "provider-card-title"}`}>{item?.title}</h3>
                            {item?.id === 3 ?  <p className="provider-detail">View Details</p> : null}
                        </div>
                    </div>
                </div>
            </div>  
        </>
    )
}

export default ProviderProgressBar
